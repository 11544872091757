<div class="navigator-logo-container">
  <div class="navigator-logo" [class.collapsed]="navigator.collapsed"></div>
  <button class="menu-collapse-button cursor-pointer" (click)="collapse()">
    <span
      class="menu-collapse-icon"
      [class.icon-collapsed]="navigator.collapsed"
    ></span>
  </button>
</div>

<div class="navigator-container">
  <div class="navigator-main">
    <div class="navigator-side-top">
      <ng-container *nonVisitor>
        <div
          class="odd-shape-container"
          *ngIf="
            appState.hasRight([
              UserAction.ManageProducts,
              UserAction.ManageSupplierOffers,
              UserAction.ManageReceivedOffers,
              UserAction.ManageClients,
            ])
          "
        >
          <span
            class="odd-shape-icon box-plus-icon"
            [class.active]="navigator.currentView === 1"
          ></span>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            width="55"
            height="56"
            viewBox="0 0 55 56"
          >
            <a
              class="odd-shape-link"
              [class.active]="navigator.currentView === 1"
              (click)="toggleView()"
            >
              <defs>
                <linearGradient
                  id="odd-shape-gradient"
                  x1="-2.489%"
                  x2="31.863%"
                  y1="-55.313%"
                  y2="120.759%"
                >
                  <stop offset="0%" stop-color="#F04B54" />
                  <stop offset="100%" stop-color="#F7921E" />
                </linearGradient>
                <path id="a" d="M33.517 0L55 12.422V56H17.653L0 46V0h33.517z" />
              </defs>
              <use
                fill="#5E5357"
                fill-rule="evenodd"
                transform="matrix(-1 0 0 1 55 0)"
                xlink:href="#a"
              />
            </a>
          </svg>
        </div>
      </ng-container>
    </div>
    <nav class="navigator-links">
      <mat-tab-group
        class="navigator-tab-group no-tab-header"
        animationDuration="0"
        [(selectedIndex)]="navigator.currentView"
      >
        <mat-tab label="Main">
          <a
            class="navigator-link"
            routerLink="/dashboard"
            routerLinkActive="active"
            [title]="'navigator.links.dashboard' | translate"
          >
            <span class="navigator-link-icon dashboard-icon"></span>
            <span class="navigator-link-text">{{
              "navigator.links.dashboard" | translate
            }}</span>
          </a>

          <a
            class="navigator-link"
            routerLink="/notifications"
            routerLinkActive="active"
            *nonVisitor
            [title]="'navigator.links.notifications' | translate"
          >
            <span
              class="navigator-link-icon notifications-icon"
              [matBadge]="
                state.inbox.notificationsBadgeCount >= 10
                  ? '9+'
                  : state.inbox.notificationsBadgeCount
              "
              [matBadgeHidden]="!state.inbox.notificationsBadgeCount"
              matBadgeSize="small"
              matBadgeColor="warn"
            ></span>
            <span class="navigator-link-text">{{
              "navigator.links.notifications" | translate
            }}</span>
          </a>

          <ng-container *ngIf="appState.hasModule(LxmAppModule.SellerCrm)">
            <a
              class="navigator-link"
              routerLink="/clients"
              routerLinkActive="active"
              *requiredRights="[
                UserAction.ViewClients,
                UserAction.ViewOthersClients,
              ]"
              [title]="'navigator.links.clients' | translate"
            >
              <span class="navigator-link-icon clients-icon"></span>
              <span class="navigator-link-text">{{
                "navigator.links.clients" | translate
              }}</span>
            </a>
          </ng-container>

          <ng-container *ngIf="appState.hasModule(LxmAppModule.BuyerCrm)">
            <a
              class="navigator-link"
              routerLink="/suppliers"
              routerLinkActive="active"
              *requiredRights="[UserAction.ViewSuppliers]"
              [title]="'navigator.links.suppliers' | translate"
            >
              <span class="navigator-link-icon clients-icon"></span>
              <span class="navigator-link-text">{{
                "navigator.links.suppliers" | translate
              }}</span>
            </a>
          </ng-container>

          <ng-container
            *ngIf="
              appState.hasModule(LxmAppModule.ViewMarketCalendar) ||
              appState.hasModule(LxmAppModule.ManageCalendar)
            "
          >
            <a
              class="navigator-link"
              routerLink="/calendar"
              routerLinkActive="active"
              *ngIf="
                appState.hasRight([
                  UserAction.ManageOfferCalendar,
                  UserAction.ViewPublishedOffersCalendar,
                ])
              "
              [title]="'navigator.links.market' | translate"
            >
              <span class="navigator-link-icon calendar-icon"></span>
              <span class="navigator-link-text">{{
                "navigator.links.calendar" | translate
              }}</span>
            </a>
          </ng-container>

          <ng-container *ngIf="appState.hasModule(LxmAppModule.Locations)">
            <a
              class="navigator-link"
              routerLink="/locations"
              routerLinkActive="active"
              *requiredRights="[UserAction.ViewLocations]"
              [title]="'navigator.links.location' | translate"
            >
              <span class="navigator-link-icon location-icon"></span>
              <span class="navigator-link-text">{{
                "navigator.links.location" | translate
              }}</span>
            </a>
          </ng-container>

          <a
            class="navigator-link"
            routerLink="/offers"
            routerLinkActive="active"
            *ngIf="
              appState.hasRight([
                UserAction.ViewSupplierOffers,
                UserAction.ViewReceivedOffers,
              ])
            "
            [title]="'navigator.links.offers' | translate"
          >
            <span
              class="navigator-link-icon offers-icon"
              [matBadge]="
                state.inbox.offersBadgeCount >= 10
                  ? '9+'
                  : state.inbox.offersBadgeCount
              "
              [matBadgeHidden]="!state.inbox.offersBadgeCount"
              matBadgeSize="small"
              matBadgeColor="warn"
            >
            </span>
            <span class="navigator-link-text">{{
              "navigator.links.offers" | translate
            }}</span>
          </a>

          <ng-container
            *ngIf="
              appState.hasModule(LxmAppModule.PurchaseManagerDesktop) &&
              appState.hasRight([UserAction.ManageReceivedOffers])
            "
          >
            <a
              class="navigator-link"
              routerLink="/workspace"
              routerLinkActive="active"
              [title]="'navigator.links.purchase_manager_workspace' | translate"
            >
              <span
                class="navigator-link-icon purchase-manager-workspace-icon"
              ></span>
              <span class="navigator-link-text">
                {{ "navigator.links.purchase_manager_workspace" | translate }}
              </span>
            </a>
          </ng-container>

          <ng-container *ngIf="appState.hasModule(LxmAppModule.Pim)">
            <a
              class="navigator-link"
              routerLink="/products"
              routerLinkActive="active"
              *requiredRights="[UserAction.ViewProducts]"
              [title]="'navigator.links.products' | translate"
            >
              <span class="navigator-link-icon products-icon"></span>
              <span class="navigator-link-text">
                {{ "navigator.links.products" | translate }}
              </span>
              <svg-icon
                *ngIf="showProductReactiveLinks"
                key="arrowMenuSuperSmall"
                fontSize="11px"
                class="reactive-link-menu-arrow"
              ></svg-icon>
              <!-- <toggle-arrow [toggled]="true"></toggle-arrow> -->
            </a>
            <reactive-links
              *ngIf="showProductReactiveLinks"
              [options]="navigator.productReactiveLinkOptions"
            >
            </reactive-links>
          </ng-container>

          <ng-container
            *ngIf="
              appState.hasModule(LxmAppModule.ViewMarket) &&
              appState.hasRight([UserAction.ViewMarket])
            "
          >
            <a
              class="navigator-link"
              routerLink="/market"
              routerLinkActive="active"
              [title]="'navigator.links.products_retailer' | translate"
            >
              <span class="navigator-link-icon products-global-icon"></span>
              <span class="navigator-link-text">
                {{ "navigator.links.market" | translate }}
              </span>
            </a>
            <reactive-links
              *ngIf="showMarketReactiveLinks"
              [options]="navigator.productReactiveLinkOptions"
            >
            </reactive-links>
          </ng-container>

          <ng-container
            *ngIf="appState.hasModule(LxmAppModule.SendProductUpdates)"
          >
            <a
              class="navigator-link"
              routerLink="/alterations"
              routerLinkActive="active"
              *requiredRights="[UserAction.ViewProductUpdates]"
              [title]="'navigator.links.alterations' | translate"
            >
              <span class="navigator-link-icon alterations-icon"></span>
              <span class="navigator-link-text">{{
                "navigator.links.alterations" | translate
              }}</span>
            </a>
          </ng-container>

          <!-- E-STORE -->
          <!-- *requiredModules="[]" -->
          <ng-container>
            <!-- *requiredRights="[]" -->

            <a
              class="navigator-link"
              routerLink="/store"
              routerLinkActive="active"
              [title]="'navigator.links.store' | translate"
            >
              <span class="navigator-link-icon store-icon"></span>
              <span class="navigator-link-text">{{
                "navigator.links.store" | translate
              }}</span>
            </a>
          </ng-container>

          <ng-container
            *requiredModules="['CreatePurchaseOrders', 'CreateSalesOrders']"
          >
            <a
              class="navigator-link"
              routerLink="/orders"
              routerLinkActive="active"
              *requiredRights="[
                UserAction.ViewPurchaseOrders,
                UserAction.ViewSalesOrders,
              ]"
              [title]="'navigator.links.orders' | translate"
            >
              <span
                class="navigator-link-icon orders-icon"
                [matBadge]="
                  state.inbox.ordersBadgeCount >= 10
                    ? '9+'
                    : state.inbox.ordersBadgeCount
                "
                [matBadgeHidden]="!state.inbox.ordersBadgeCount"
                matBadgeSize="small"
                matBadgeColor="warn"
              ></span>
              <span class="navigator-link-text">{{
                "navigator.links.orders" | translate
              }}</span>
            </a>
          </ng-container>

          <ng-container *ngIf="appState.hasModule(LxmAppModule.Pim)">
            <a
              class="navigator-link"
              routerLink="/import"
              routerLinkActive="active"
              *requiredRights="[UserAction.ImportProducts]"
              [title]="'navigator.links.imports' | translate"
            >
              <span class="navigator-link-icon imports-icon"></span>
              <span class="navigator-link-text">{{
                "navigator.links.imports" | translate
              }}</span>
            </a>
          </ng-container>

          <a
            class="navigator-link coming-soon"
            routerLink="/reports"
            routerLinkActive="active"
            *requiredRights="[UserAction.Reports]"
            [title]="'navigator.links.reports' | translate"
          >
            <span class="navigator-link-icon reports-icon"></span>
            <span class="navigator-link-text">{{
              "navigator.links.reports" | translate
            }}</span>
          </a>

          <a
            class="navigator-link"
            routerLink="/settings"
            routerLinkActive="active"
            *ngIf="appState.hasRight([UserAction.ViewClvs])"
            [title]="'navigator.links.settings' | translate"
          >
            <span class="navigator-link-icon settings-icon"></span>
            <span class="navigator-link-text">{{
              "navigator.links.settings" | translate
            }}</span>
          </a>
        </mat-tab>

        <mat-tab label="Secondary">
          <!-- ADD PRODUCT -->
          <a
            class="navigator-link"
            (click)="navigateQuickAction('/products/new')"
            routerLink="/products/new"
            *requiredRights="[UserAction.ManageProducts]"
            [title]="'navigator.quick_links.add_product' | translate"
          >
            <span class="navigator-link-icon products-icon"></span>
            <span class="navigator-link-text">{{
              "navigator.quick_links.add_product" | translate
            }}</span>
          </a>

          <!-- REQUEST OFFER -->
          <a
            class="navigator-link"
            (click)="navigateQuickAction('/offers/request')"
            routerLink="/offers/request"
            *requiredRights="[UserAction.ReceiveOffers]"
            [title]="'navigator.quick_links.request_offer' | translate"
          >
            <span class="navigator-link-icon offers-icon"></span>
            <span class="navigator-link-text">{{
              "navigator.quick_links.request_offer" | translate
            }}</span>
          </a>

          <!-- PURCHASE ORDERS -->
          <ng-container
            *ngIf="appState.hasModule(LxmAppModule.CreatePurchaseOrders)"
          >
            <a
              class="navigator-link"
              (click)="navigateQuickAction('/orders/purchase/new')"
              routerLink="/orders/purchase/new"
              *requiredRights="[UserAction.ManageOfferCalendar]"
              [title]="
                'navigator.quick_links.create_purchase_order' | translate
              "
            >
              <span class="navigator-link-icon orders-icon"></span>
              <span class="navigator-link-text">{{
                "navigator.quick_links.create_purchase_order" | translate
              }}</span>
            </a>
          </ng-container>

          <!-- SALES ORDERS -->
          <ng-container
            *ngIf="appState.hasModule(LxmAppModule.CreateSalesOrders)"
          >
            <a
              class="navigator-link"
              (click)="navigateQuickAction('/orders/sales/new')"
              routerLink="/orders/sales/new"
              *requiredRights="[UserAction.ManageOfferCalendar]"
              [title]="'navigator.quick_links.create_sales_order' | translate"
            >
              <span class="navigator-link-icon orders-icon"></span>
              <span class="navigator-link-text">{{
                "navigator.quick_links.create_sales_order" | translate
              }}</span>
            </a>
          </ng-container>

          <!-- NEW OFFER -->
          <a
            class="navigator-link"
            (click)="navigateQuickAction('/offers/new')"
            routerLink="/offers/new"
            *requiredRights="[UserAction.ManageSupplierOffers]"
            [title]="'navigator.quick_links.new_offer' | translate"
          >
            <span class="navigator-link-icon offers-icon"></span>
            <span class="navigator-link-text">{{
              "navigator.quick_links.new_offer" | translate
            }}</span>
          </a>

          <!-- NEW CALENDAR ENTRY - still needs to open dialog -->
          <ng-container *ngIf="appState.hasModule(LxmAppModule.ManageCalendar)">
            <a
              class="navigator-link"
              (click)="navigateQuickAction('/calendar', ['newCalendarEntry'])"
              routerLink="/calendar"
              *requiredRights="[UserAction.ManageOfferCalendar]"
              [title]="'navigator.quick_links.new_calendar_entry' | translate"
            >
              <span class="navigator-link-icon calendar-icon"></span>
              <span class="navigator-link-text">{{
                "navigator.quick_links.new_calendar_entry" | translate
              }}</span>
            </a>
          </ng-container>

          <!-- ADD CLIENT -->
          <ng-container *ngIf="appState.hasModule(LxmAppModule.SellerCrm)">
            <a
              class="navigator-link"
              (click)="navigateQuickAction('/clients/new')"
              routerLink="/clients/new"
              [queryParams]="{ action: 'addUser' }"
              *requiredRights="[UserAction.ManageClients]"
              [title]="'navigator.quick_links.add_client' | translate"
            >
              <span class="navigator-link-icon clients-icon"></span>
              <span class="navigator-link-text">{{
                "navigator.quick_links.add_client" | translate
              }}</span>
            </a>
          </ng-container>

          <!-- ADD SUPPLIER -->
          <ng-container *ngIf="appState.hasModule(LxmAppModule.BuyerCrm)">
            <a
              class="navigator-link"
              (click)="navigateQuickAction('/suppliers/new')"
              routerLink="/suppliers/new"
              *requiredRights="[UserAction.ManageSuppliers]"
              [title]="'navigator.quick_links.add_supplier' | translate"
            >
              <span class="navigator-link-icon clients-icon"></span>
              <span class="navigator-link-text">{{
                "navigator.quick_links.add_supplier" | translate
              }}</span>
            </a>
          </ng-container>

          <!-- ADD LOCATION -->
          <ng-container *ngIf="appState.hasModule(LxmAppModule.Locations)">
            <a
              class="navigator-link"
              (click)="navigateQuickAction('/locations/new')"
              routerLink="/locations/new"
              *requiredRights="[UserAction.ManageLocations]"
              [title]="'navigator.quick_links.add_location' | translate"
            >
              <span class="navigator-link-icon location-icon"></span>
              <span class="navigator-link-text">{{
                "navigator.quick_links.add_location" | translate
              }}</span>
            </a>
          </ng-container>

          <!-- ADD USER -->
          <ng-container *requiredRights="[UserAction.ManageUsers]">
            <a
              class="navigator-link"
              (click)="navigateQuickAction('/lxm-settings/users', ['addUser'])"
              routerLink="/lxm-settings/users"
              [title]="'navigator.quick_links.add_user' | translate"
            >
              <span class="navigator-link-icon settings-icon"></span>
              <span class="navigator-link-text">{{
                "navigator.quick_links.add_user" | translate
              }}</span>
            </a>
          </ng-container>
        </mat-tab>
      </mat-tab-group>
    </nav>
  </div>

  <div class="navigator-side-bottom">
    <!-- ? MENU -->
    <ng-container *nonVisitor>
      <div class="odd-shape-container">
        <span class="odd-shape-icon box-question-icon"></span>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="55"
          height="64"
          viewBox="0 0 55 64"
        >
          <a
            class="odd-shape-link"
            [class.active]="infoTrigger.menuOpen"
            #infoTrigger="matMenuTrigger"
            [matMenuTriggerFor]="infoMenu"
          >
            <defs>
              <linearGradient
                id="odd-shape-gradient"
                x1="-2.489%"
                x2="31.863%"
                y1="-55.313%"
                y2="120.759%"
              >
                <stop offset="0%" stop-color="#F04B54" />
                <stop offset="100%" stop-color="#F7921E" />
              </linearGradient>
            </defs>
            <path
              fill="#5E5357"
              fill-rule="evenodd"
              d="M17.653 0L0 10v53.577L16.95 54H55V0z"
            />
          </a>
        </svg>
      </div>
      <mat-menu #infoMenu="matMenu" class="navigator-menu navigator-info-menu">
        <ng-container *requiredRights="[UserAction.ReceiveProcurements]">
          <a
            *ngIf="supplierFrVgInstructionsLink"
            class="navigator-menu-button"
            mat-menu-item
            href="{{ supplierFrVgInstructionsLink }}"
            target="_blank"
            >{{ "navigator.info_menu.supplier_frvg" | translate }}</a
          >
        </ng-container>
        <ng-container *requiredRights="[UserAction.ViewProducts]">
          <a
            *ngIf="packagingDataInstructionsLink"
            class="navigator-menu-button"
            mat-menu-item
            href="{{ packagingDataInstructionsLink }}"
            target="_blank"
            >{{
              "navigator.info_menu.packaging_data_instructions" | translate
            }}</a
          >
        </ng-container>
        <ng-container *requiredRights="[UserAction.ManagePurchaseOrders]">
          <a
            *ngIf="ordersInstructionsLink"
            class="navigator-menu-button"
            mat-menu-item
            href="{{ ordersInstructionsLink }}"
            target="_blank"
            >{{ "navigator.info_menu.orders_instructions" | translate }}</a
          >
        </ng-container>
        <ng-container *nonVisitor>
          <a
            *ngIf="userManagementLink"
            class="navigator-menu-button"
            mat-menu-item
            href="{{ userManagementLink }}"
            target="_blank"
          >
            {{ "navigator.info_menu.user_management" | translate }}
          </a>
        </ng-container>
        <ng-container *requiredRights="[UserAction.ManageProcurements]">
          <a
            *ngIf="pujuLink"
            class="navigator-menu-button"
            mat-menu-item
            href="{{ pujuLink }}"
            target="_blank"
          >
            {{ "navigator.info_menu.frvg" | translate }}
          </a>
        </ng-container>
        <a
          class="navigator-menu-button"
          mat-menu-item
          href="{{ contactLink }}"
          target="_blank"
          >{{ "navigator.info_menu.contact" | translate }}</a
        >
        <span class="app-version"
          >{{ "global.version" | translate }}: {{ version }}</span
        >
      </mat-menu>
    </ng-container>

    <!-- USER MENU -->
    <div class="odd-shape-container">
      <span class="odd-shape-icon box-profile-icon"></span>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="55"
        height="56"
        viewBox="0 0 55 56"
      >
        <a
          class="odd-shape-link"
          [class.active]="userTrigger.menuOpen"
          #userTrigger="matMenuTrigger"
          [matMenuTriggerFor]="userMenu"
        >
          <defs>
            <linearGradient
              id="odd-shape-gradient"
              x1="-2.489%"
              x2="31.863%"
              y1="-55.313%"
              y2="120.759%"
            >
              <stop offset="0%" stop-color="#F04B54" />
              <stop offset="100%" stop-color="#F7921E" />
            </linearGradient>
          </defs>
          <path
            fill="#5E5357"
            fill-rule="evenodd"
            d="M17.653 0L0 10v46h33.518L55 43.578V0z"
          />
        </a>
      </svg>
    </div>
    <mat-menu
      #userMenu="matMenu"
      class="navigator-menu navigator-user-menu"
      focusOnOpen="false"
    >
      <div class="navigator-menu-tenants" [ngSwitch]="showTenantsFilter">
        <ng-container *ngSwitchCase="true" [formGroup]="form">
          <lxm-select
            style="padding: 8px"
            for="tenant"
            [options]="tenants"
            [searchable]="true"
            value="id"
            [clearable]="false"
            (change)="switchTenant($event.id)"
            stop-propagation
          >
          </lxm-select>
        </ng-container>
        <ng-container *ngSwitchDefault>
          <a
            *ngFor="let tenant of tenants"
            mat-menu-item
            class="navigator-menu-button"
            [class.current]="tenant.id === tenantId"
            (click)="switchTenant(tenant.id)"
            tabIndex="-1"
          >
            {{ tenant.name }}
          </a>
        </ng-container>
      </div>

      <span class="navigator-menu-header">
        {{ state.user?.name || state.user?.email }}
      </span>
      <div
        class="navigator-menu-button navigator-menu-select-button"
        mat-menu-item
        (click)="$event.stopPropagation()"
      >
        <mat-select
          class="navigator-menu-select"
          [compareWith]="languageValueCompare"
          [(ngModel)]="locale.currentLanguage"
          (selectionChange)="changeLanguage($event.value)"
          panelClass="navigator-menu-select-backdrop"
        >
          <mat-select-trigger>
            <div class="align-center flex flex-row" style="line-height: 1em">
              <svg-icon
                [key]="getLanguageIcon(locale.currentLanguage.id)"
                fontSize="16px"
                class="mr10"
                matPrefix
              ></svg-icon>
              {{ locale.currentLanguage.name }}
            </div>
          </mat-select-trigger>
          <mat-option
            class="navigator-menu-select-option"
            *ngFor="let lang of languageOptions"
            [value]="lang"
          >
            <div class="align-center flex flex-row" style="line-height: 1em">
              <svg-icon
                [key]="getLanguageIcon(lang.id)"
                fontSize="16px"
                class="mr10"
                matPrefix
              ></svg-icon>
              {{ lang.name }}
            </div>
          </mat-option>
        </mat-select>
      </div>

      <a
        class="navigator-menu-button"
        mat-menu-item
        routerLink="/user-settings"
        routerLinkActive="active"
        *nonVisitor
      >
        {{ "navigator.user_menu.user_settings" | translate }}
      </a>

      <ng-container
        *ngIf="
          appState.hasRight([UserAction.ManageSettings, UserAction.ViewUsers])
        "
      >
        <a
          class="navigator-menu-button"
          mat-menu-item
          [routerLink]="adminLinkUrl"
          routerLinkActive="active"
        >
          {{ "navigator.user_menu.lexi_market_settings" | translate }}
        </a>
      </ng-container>
      <a class="navigator-menu-button" mat-menu-item (click)="logout()">
        {{ "navigator.user_menu.log_out" | translate }}
      </a>
    </mat-menu>
  </div>
</div>
