import { NgModule, ModuleWithProviders, LOCALE_ID } from "@angular/core";

import {
  HttpClientModule,
  HttpClient,
  HTTP_INTERCEPTORS,
} from "@angular/common/http";

import { MatStepperModule } from "@angular/material/stepper";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatDialogModule } from "@angular/material/dialog";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatInputModule } from "@angular/material/input";
import { MatMenuModule } from "@angular/material/menu";
import { MatPaginatorModule } from "@angular/material/paginator";
import { MatProgressBarModule } from "@angular/material/progress-bar";
import { MatSelectModule } from "@angular/material/select";
import { MatSlideToggleModule } from "@angular/material/slide-toggle";
import { MatSnackBarModule } from "@angular/material/snack-bar";
import { MatSortModule } from "@angular/material/sort";
import { MatTableModule } from "@angular/material/table";
import { MatTabsModule } from "@angular/material/tabs";
import { MatBadgeModule } from "@angular/material/badge";

import {
  MAT_MOMENT_DATE_ADAPTER_OPTIONS,
  MAT_MOMENT_DATE_FORMATS,
  MomentDateAdapter,
} from "@angular/material-moment-adapter";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import {
  DateAdapter,
  MAT_DATE_LOCALE,
  MAT_DATE_FORMATS,
} from "@angular/material/core";

//
import { NgSelectModule } from "@ng-select/ng-select";
import { NgxMaterialTimepickerModule } from "ngx-material-timepicker";
import { ScrollingModule } from "@angular/cdk/scrolling";
import { AutosizeModule } from "ngx-autosize";
import { NgxPopperModule } from "ngx-popper";
import { LightboxModule } from "ngx-lightbox";
import { MomentModule } from "ngx-moment";
import { SvgIconsModule } from "@ngneat/svg-icon";

// DIRECTIVES
import * as directives from "./directives";

import { LxmTextareaAutosize } from "./directives/lxm-autosize";
import {
  LxmTreeCellDefDirective,
  LxmTreeColumnDefDirective,
  LxmTreeHeaderDefDirective,
} from "./components/lxm-tree/lxm-tree-def.directive";
import { DataTableFilterDirective } from "src/app/directives/data-table-filter.directive";
import { DataTableComponent } from "src/app/components/data-table/data-table.component";
import { ClvTableComponent } from "src/app/components/_partials/settings/clv-table/clv-table.component";

// PIPES
import * as pipes from "./pipes";

// i18n
import { TranslateModule } from "@ngx-translate/core";

// COMPONENTS
import * as components from "./components";

import { ReactiveLinksComponent } from "./components/_layout/navigator/reactive-links/reactive-links.component";

import { AddFileDialog } from "./dialogs/add-file/add-file.dialog";
import { AddImageDialog } from "./dialogs/add-image/add-image.dialog";
import { AliasComponent } from "./components/_elements/alias/alias.component";
import { ImagePopoverComponent } from "./components/_elements/popover/image-popover/image-popover.component";
import {
  SupplierProductPriceInfoPopoverComponent,
  RetailerProductPriceInfoPopoverComponent,
  MarketProductPriceInfoPopoverComponent,
  PurchaseManagerDesktopProductPriceInfoPopoverComponent,
  PurchaseOrderProductPriceInfoPopoverComponent,
} from "./components/_elements/popover/product-price-info-popover/product-price-info-popover.component";
import { ProductClvTableComponent } from "./components/_partials/settings/clv-table/product-management/product-clv-table.component";
import { LxmClvTableComponent } from "./components/_partials/settings/clv-table/lxm/lxm-clv-table.component";
import { AssortmentClvTableComponent } from "./components/_partials/settings/clv-table/assortment-management/assortment-clv-table.component";
import { InfoArrayComponent } from "./components/_elements/info-array/info-array.component";
import { ProductCodeManagerComponent } from "./components/_elements/managers/product-code-manager/product-code-manager.component";
import { TareManagerComponent } from "./components/_elements/managers/tare-manager/tare-manager.component";
import { PackageMaterialManagerComponent } from "./components/_elements/managers/package-material-manager/package-material.component";
import { DepositManagerComponent } from "./components/_elements/managers/deposit-manager/deposit-manager.component";
import { ProductPaymentManagerComponent } from "./components/_elements/managers/product-payment-manager/product-payment-manager.component";
import { UsersTableComponent } from "./components/_partials/lxm-settings/users-table/users-table.component";
import { RolesTableComponent } from "./components/_partials/lxm-settings/roles-table/roles-table.component";
import { ConfirmationDialog } from "./dialogs/confirm/confirm.dialog";
import { ProductSegmentTreeComponent } from "./components/_partials/settings/product-segment-tree/product-segment-tree.component";
import { GpcTreeComponent } from "./components/_partials/settings/gpc-tree/gpc-tree.component";
import { AtcTreeComponent } from "./components/_partials/settings/atc-tree/atc-tree.component";
import { TimeDiffComponent } from "./components/_elements/time-diff/time-diff.component";
import { MessageComponent } from "./components/_layout/messages/message/message.component";
import { ProductAllergensListComponent } from "./components/_partials/product-allergens-list/product-allergens-list.component";
import { ProductRetailerExpiryListComponent } from "./components/_partials/product-retailer-expiry-list/product-retailer-expiry-list.component";
import { FilesListComponent } from "./components/_partials/files-list/files-list.component";
import { SupplierOfferProductsListComponent } from "./components/_partials/offer-products/supplier-offer/supplier-offer-products.component";
import { ReceivingOfferProductsListComponent } from "./components/_partials/offer-products/receiving-offer/receiving-offer-products.component";
import { RequestOfferProductsListComponent } from "./components/_partials/offer-products/request-offer/request-offer-products.component";
import { TermsDialogComponent } from "./components/_partials/terms-and-conditions/terms.dialog";
import { GuestLanguageSelectorComponent } from "./components/_partials/guest-language-selector/guest-language-selector.component";

import { LocaleService } from "./_services";

import icons from "../assets/svg/svg-icons";

import { CopyrightBlockComponent } from "src/app/components";

// EXTENSIONS
import "src/app/ext/observable-result";

// HELPERS
import {
  JwtInterceptor,
  ErrorInterceptor,
  ApiInterceptor,
  JsonDateInterceptor,
} from "./_helpers";
import { CookieService } from "ngx-cookie-service";

import { CommonModule, CurrencyPipe } from "@angular/common";

import localeEt from "@angular/common/locales/et";
import localeEn from "@angular/common/locales/en";
import { registerLocaleData } from "@angular/common";
import { HistoryCardComponent } from "./cards/history/history.card";
import { DiffComponent } from "./cards/history/diff.component";
import { MovieFormatListComponent } from "./components/_partials/movie-format-list/movie-format-list.component";
import { MovieAudioListComponent } from "./components/_partials/movie-audio-list/movie-audio-list.component";
import { MovieActorListComponent } from "./components/_partials/movie-actor-list/movie-actor-list.component";
import { BookRoleListComponent } from "./components/_partials/book-role-list/book-role-list.component";
import { MusicTrackListComponent } from "./components/_partials/music-track-list/music-track-list.component";
import { LinksListComponent } from "./components/_partials/links-list/links-list.component";
import { AddLinkDialog } from "./dialogs/add-link/add-link.dialog";
import { ProductProcurementListComponent } from "./components/_partials/product-description-list/product-procurement-list.component";
import { LxmQuillModule } from "./modules/lxm-quill.module";
import { ProcurementDetailsPopoverComponent } from "./components/_elements/popover/procurement-details-popover/procurement-details-popover.component";
import {
  ProductSchemeDisplayPriceComponent,
  ProductSchemeUnitPriceComponent,
} from "./components/_elements/product-price-info/product-scheme-price.component";

import { BreadcrumbModule } from "xng-breadcrumb";
import { BreadcrumbService } from "xng-breadcrumb";
import { DragDropModule } from "@angular/cdk/drag-drop";
import { AssortmentGroupTagsComponent } from "./components/_partials/settings/assortment-group-tags/assortment-group-tags.component";
import { ActionHelper } from "./_helpers/action";
import { TagComponent } from "./components/_elements/tag/tag.component";
import { PictogramHelper } from "./_helpers/pictograms";
import { PriceGroupTableComponent } from "./components/form/price-tables/price-group-table/price-group-table.component";
import { CurrencyPriceTableComponent } from "./components/form/price-tables/currency-price-table/currency-price-table.component";

import { OfferProductAssortmentAndPricesDialog } from "./components/_partials/offer-products/dialog/offer-product-assortment-and-prices/offer-product-assortment-and-prices.dialog";
import { OfferProductSupplierPricesComponent } from "./components/_partials/offer-products/dialog/offer-product-assortment-and-prices/supplier-prices/offer-product-supplier-prices.component";
import { OfferProductAssortmentComponent } from "./components/_partials/offer-products/dialog/offer-product-assortment-and-prices/assortment/offer-product-assortment.component";
import { DescriptionPopoverComponent } from "./components/_elements/popover/description-popover/description-popover.component";
import { ProductAssortmentCopyDialog } from "./components/_partials/offer-products/dialog/offer-product-assortment-and-prices/assortment/copy-dialog/assortment-copy.dialog";
import { NgxMaskModule } from "ngx-mask";
import { ExportProductsPdfDialog } from "./dialogs/export-products-pdf/export-products-pdf.dialog";
import { SyncProductsDialog } from "./dialogs/sync-products/sync-products.dialog";
import { PriceChangeDisplayComponent } from "./components/_elements/price-change-display/price-change-display.component";
import { OverlayViewComponent } from "./components/_layout/overlay-view/overlay-view.component";
import { OverlayViewHeaderComponent } from "./components/_layout/overlay-view/overlay-header/ov-header.component";
import { NgxSkeletonLoaderModule } from "ngx-skeleton-loader";
import { OverlayViewOptionsComponent } from "./components/_layout/overlay-view/options/ov-options.component";
import { OverlayViewMenuItemComponent } from "./components/_layout/overlay-view/menu-item/ov-menu-item.component";
import { LxmCalendarComponent } from "./components/form/calendar/calendar.component";
import { PrettyPrintComponent } from "./components/_elements/prettyprint/prettyprint.component";

registerLocaleData(localeEt, "et-EE");
registerLocaleData(localeEn, "en-US");

@NgModule({
  declarations: [
    AddFileDialog,
    AddImageDialog,
    components.CurrencyPriceComponent,
    components.DialogComponent,
    components.CardComponent,
    components.CardExpandingComponent,
    components.SectionComponent,
    components.SectionExpandingComponent,
    components.ExpanderComponent,
    components.FormFieldComponent,
    components.CombinedInputComponent,
    components.ScopeComponent,
    components.SelectComponent,
    components.AutocompleteComponent,
    components.ButtonComponent,
    components.DropdownButtonComponent,
    components.LxmDatepickerComponent,
    components.LxmRangepickerComponent,
    components.LanguageTextComponent,
    components.ValidationErrorsComponent,
    components.LxmRadioComponent,
    components.LxmTextareaComponent,
    components.LxmInputComponent,
    components.LxmGmapInputComponent,
    components.LxmCheckboxComponent,
    components.ChatComponent,
    components.SearchComponent,
    components.FileDropBoxComponent,
    components.ImageDropBoxComponent,
    components.LogoBlockComponent,
    components.LogoImageDialogComponent,
    components.DotMenuComponent,
    components.OverlayComponent,
    components.LockerComponent,
    components.GoogleMapComponent,
    components.LxmTimepickerComponent,
    components.ImageBoxComponent,
    components.NoContentComponent,
    components.LoadingSpinnerComponent,
    components.DialogSpinnerComponent,
    components.ViewSpinnerComponent,
    components.StatusCircleComponent,
    components.ProgressBarComponent,
    components.StatusIndicatorComponent,
    components.ToggleArrowComponent,
    components.SlideToggleComponent,
    components.CombinedToggleButtonComponent,
    components.ApartToggleButtonComponent,
    components.TypeIndicatorComponent,
    components.TabOptionsComponent,
    components.PackageInfoComponent,
    components.LxmProgressBarComponent,
    components.ZoomControlsComponent,
    components.TextDataTableFilterComponent,
    components.DropdownDataTableFilterComponent,
    components.LxmTreeComponent,
    components.LxmTreeNodeComponent,
    components.LxmTreeHeaderComponent,
    components.LxmTreeCellComponent,
    components.CardMessageComponent,
    components.TooltipComponent,
    components.UserComponent,
    components.DataTableFooterComponent,
    components.DataHoverComponent,
    components.PhoneNumberComponent,
    components.OwnerSelectComponent,
    components.DisplayProductComponent,
    components.ColorBoxComponent,
    components.ColorPickerComponent,
    components.AssortmentGroupSelectorComponent,
    components.LxmBreadcrumbComponent,
    AliasComponent,
    ReactiveLinksComponent,
    ImagePopoverComponent,
    SupplierProductPriceInfoPopoverComponent,
    RetailerProductPriceInfoPopoverComponent,
    MarketProductPriceInfoPopoverComponent,
    PurchaseManagerDesktopProductPriceInfoPopoverComponent,
    PurchaseOrderProductPriceInfoPopoverComponent,
    ProductSchemeDisplayPriceComponent,
    ProductSchemeUnitPriceComponent,
    ProcurementDetailsPopoverComponent,
    DataTableComponent,
    ClvTableComponent,
    ProductClvTableComponent,
    LxmClvTableComponent,
    AssortmentClvTableComponent,
    UsersTableComponent,
    RolesTableComponent,
    ProductSegmentTreeComponent,
    GpcTreeComponent,
    AtcTreeComponent,
    InfoArrayComponent,
    ProductCodeManagerComponent,
    TareManagerComponent,
    PackageMaterialManagerComponent,
    DepositManagerComponent,
    ProductPaymentManagerComponent,
    ConfirmationDialog,
    MessageComponent,
    TimeDiffComponent,
    ProductAllergensListComponent,
    ProductRetailerExpiryListComponent,
    FilesListComponent,
    SupplierOfferProductsListComponent,
    ReceivingOfferProductsListComponent,
    RequestOfferProductsListComponent,
    TermsDialogComponent,
    ExportProductsPdfDialog,
    SyncProductsDialog,
    GuestLanguageSelectorComponent,
    HistoryCardComponent,
    DiffComponent,
    CopyrightBlockComponent,
    AssortmentGroupTagsComponent,
    TagComponent,
    PriceGroupTableComponent,
    CurrencyPriceTableComponent,
    DescriptionPopoverComponent,
    PriceChangeDisplayComponent,
    OverlayViewComponent,

    OfferProductAssortmentAndPricesDialog,
    OfferProductSupplierPricesComponent,
    OfferProductAssortmentComponent,

    LxmCalendarComponent,

    ProductAssortmentCopyDialog,
    OverlayViewHeaderComponent,
    PrettyPrintComponent,
    OverlayViewOptionsComponent,
    OverlayViewMenuItemComponent,
    OverlayViewHeaderComponent,

    // PIPES
    pipes.TranslateEnumPipe,
    pipes.TranslateOptionsPipe,
    pipes.FormValuePipe,
    pipes.Nl2BrPipe,
    pipes.PriceValuePipe,
    pipes.PercentValuePipe,
    pipes.NumericValuePipe,
    pipes.FormDatePipe,
    pipes.DecodeHtmlStringPipe,
    pipes.AddressPipe,
    pipes.SystemClvNamePipe,
    pipes.AuthImagePipe,
    pipes.FilesizePipe,
    pipes.TranslatedValuePipe,
    pipes.SanitizerPipe,
    pipes.SpacerPipe,
    pipes.PrettyPrintPipe,

    // DIRECTIVES
    directives.DataTableTextFilterDirective,
    directives.DataTableDropdownFilterDirective,
    directives.FileDropDirective,
    directives.BlurEnterDirective,
    directives.SingleLineDirective,
    directives.StopPropagationDirective,
    directives.StopClickDirective,
    directives.RequiredRightsDirective,
    directives.ApiPostLinkDirective,
    directives.ReadonlyDirective,
    directives.VisitorDirective,
    directives.NonVisitorDirective,
    directives.ZoomDirective,
    directives.FullscreenDirective,
    directives.HasRightDirective,
    directives.RequiredModulesDirective,
    directives.ShowPricesDirective,
    LxmTextareaAutosize,
    LxmTreeCellDefDirective,
    LxmTreeColumnDefDirective,
    LxmTreeHeaderDefDirective,
    DataTableFilterDirective,
    MovieFormatListComponent,
    MovieAudioListComponent,
    MovieActorListComponent,
    BookRoleListComponent,
    MusicTrackListComponent,
    LinksListComponent,
    ProductProcurementListComponent,
    AddLinkDialog,
  ],
  imports: [
    // // ANGULAR MODULES
    CommonModule,
    TranslateModule.forChild(),
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    MatTableModule,
    MatSelectModule,
    MatSortModule,
    MatInputModule,
    MatFormFieldModule,
    MatMenuModule,
    MatDialogModule,
    MatPaginatorModule,
    MatTabsModule,
    MatCheckboxModule,
    MatBadgeModule,
    MatSelectModule,
    MatSlideToggleModule,
    MatStepperModule,
    MatDatepickerModule,
    // MatMomentDateModule,
    MatProgressBarModule,
    ScrollingModule,
    MatSnackBarModule,
    // //
    AutosizeModule,
    NgSelectModule,
    LxmQuillModule,
    DragDropModule,
    NgxMaterialTimepickerModule,
    NgxSkeletonLoaderModule,
    MomentModule.forRoot({
      relativeTimeThresholdOptions: {
        m: 59,
      },
    }),
    NgxMaskModule.forRoot(),
    NgxPopperModule,
    LightboxModule,
    // appRouting
    SvgIconsModule.forRoot({
      sizes: {
        xs: "10px",
        sm: "12px",
        md: "16px",
        lg: "20px",
      },
      icons,
    }),
    BreadcrumbModule,
  ],
  exports: [
    CommonModule,
    TranslateModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    //
    MatTableModule,
    MatSelectModule,
    MatSortModule,
    MatInputModule,
    MatFormFieldModule,
    MatMenuModule,
    MatDialogModule,
    MatPaginatorModule,
    MatTabsModule,
    MatCheckboxModule,
    MatBadgeModule,
    MatSelectModule,
    MatSlideToggleModule,
    MatStepperModule,
    MatDatepickerModule,
    MatProgressBarModule,
    ScrollingModule,
    MatSnackBarModule,
    AutosizeModule,
    NgSelectModule,
    LxmQuillModule,
    DragDropModule,
    NgxMaterialTimepickerModule,
    MomentModule,
    NgxMaskModule,
    NgxPopperModule,
    SvgIconsModule,
    NgxSkeletonLoaderModule,

    // Components
    components.CurrencyPriceComponent,
    components.DialogComponent,
    components.CardComponent,
    components.CardExpandingComponent,
    components.SectionComponent,
    components.SectionExpandingComponent,
    components.ExpanderComponent,
    components.FormFieldComponent,
    components.CombinedInputComponent,
    components.ScopeComponent,
    components.SelectComponent,
    components.AutocompleteComponent,
    components.ButtonComponent,
    components.DropdownButtonComponent,
    components.LxmDatepickerComponent,
    components.LxmRangepickerComponent,
    components.LxmTimepickerComponent,
    components.LanguageTextComponent,
    components.ValidationErrorsComponent,
    components.LxmRadioComponent,
    components.LxmCheckboxComponent,
    components.LxmTextareaComponent,
    components.LxmInputComponent,
    components.LxmGmapInputComponent,
    components.ChatComponent,
    components.SearchComponent,
    components.FileDropBoxComponent,
    components.ImageDropBoxComponent,
    components.LogoBlockComponent,
    components.LogoImageDialogComponent,
    components.DotMenuComponent,
    components.OverlayComponent,
    components.LockerComponent,
    components.GoogleMapComponent,
    components.ImageBoxComponent,
    components.NoContentComponent,
    components.TextDataTableFilterComponent,
    components.DropdownDataTableFilterComponent,
    components.LxmTreeComponent,
    components.LxmTreeNodeComponent,
    components.LxmTreeHeaderComponent,
    components.LxmTreeCellComponent,
    components.TooltipComponent,
    components.UserComponent,
    components.CardMessageComponent,
    components.LoadingSpinnerComponent,
    components.DialogSpinnerComponent,
    components.ViewSpinnerComponent,
    components.StatusCircleComponent,
    components.ProgressBarComponent,
    components.StatusIndicatorComponent,
    components.ToggleArrowComponent,
    components.SlideToggleComponent,
    components.CombinedToggleButtonComponent,
    components.ApartToggleButtonComponent,
    components.TypeIndicatorComponent,
    components.TabOptionsComponent,
    components.PackageInfoComponent,
    components.LxmProgressBarComponent,
    components.ZoomControlsComponent,
    components.DataTableFooterComponent,
    components.DataHoverComponent,
    components.PhoneNumberComponent,
    components.OwnerSelectComponent,
    components.DisplayProductComponent,
    components.ColorBoxComponent,
    components.ColorPickerComponent,
    components.AssortmentGroupSelectorComponent,
    components.LxmBreadcrumbComponent,

    AliasComponent,
    ReactiveLinksComponent,
    ImagePopoverComponent,
    SupplierProductPriceInfoPopoverComponent,
    RetailerProductPriceInfoPopoverComponent,
    MarketProductPriceInfoPopoverComponent,
    PurchaseManagerDesktopProductPriceInfoPopoverComponent,
    PurchaseOrderProductPriceInfoPopoverComponent,
    ProductSchemeDisplayPriceComponent,
    ProductSchemeUnitPriceComponent,
    ProcurementDetailsPopoverComponent,
    DataTableComponent,
    ClvTableComponent,
    ProductClvTableComponent,
    LxmClvTableComponent,
    AssortmentClvTableComponent,
    UsersTableComponent,
    RolesTableComponent,
    ProductSegmentTreeComponent,
    GpcTreeComponent,
    AtcTreeComponent,
    InfoArrayComponent,
    ProductCodeManagerComponent,
    TareManagerComponent,
    PackageMaterialManagerComponent,
    DepositManagerComponent,
    ProductPaymentManagerComponent,
    ConfirmationDialog,
    MessageComponent,
    TimeDiffComponent,
    ProductAllergensListComponent,
    ProductRetailerExpiryListComponent,
    FilesListComponent,
    SupplierOfferProductsListComponent,
    ReceivingOfferProductsListComponent,
    RequestOfferProductsListComponent,
    TermsDialogComponent,
    ExportProductsPdfDialog,
    SyncProductsDialog,
    GuestLanguageSelectorComponent,
    HistoryCardComponent,
    DiffComponent,
    CopyrightBlockComponent,
    MovieFormatListComponent,
    MovieAudioListComponent,
    MovieActorListComponent,
    BookRoleListComponent,
    MusicTrackListComponent,
    LinksListComponent,
    ProductProcurementListComponent,
    AssortmentGroupTagsComponent,
    TagComponent,
    PriceGroupTableComponent,
    CurrencyPriceTableComponent,
    DescriptionPopoverComponent,
    PriceChangeDisplayComponent,

    OfferProductAssortmentAndPricesDialog,
    OfferProductSupplierPricesComponent,
    OfferProductAssortmentComponent,

    ProductAssortmentCopyDialog,

    LxmCalendarComponent,

    AddFileDialog,
    AddImageDialog,
    PrettyPrintComponent,
    OverlayViewOptionsComponent,
    OverlayViewMenuItemComponent,
    OverlayViewHeaderComponent,

    // PIPES
    pipes.TranslateEnumPipe,
    pipes.TranslateOptionsPipe,
    pipes.FormValuePipe,
    pipes.Nl2BrPipe,
    pipes.PriceValuePipe,
    pipes.PercentValuePipe,
    pipes.NumericValuePipe,
    pipes.FormDatePipe,
    pipes.DecodeHtmlStringPipe,
    pipes.AddressPipe,
    pipes.SystemClvNamePipe,
    pipes.AuthImagePipe,
    pipes.FilesizePipe,
    pipes.TranslatedValuePipe,
    pipes.SanitizerPipe,
    pipes.SpacerPipe,
    pipes.PrettyPrintPipe,

    // DIRECTIVES
    directives.DataTableTextFilterDirective,
    directives.DataTableDropdownFilterDirective,
    directives.FileDropDirective,
    directives.BlurEnterDirective,
    directives.SingleLineDirective,
    directives.StopPropagationDirective,
    directives.StopClickDirective,
    directives.RequiredRightsDirective,
    directives.ApiPostLinkDirective,
    directives.ReadonlyDirective,
    directives.VisitorDirective,
    directives.NonVisitorDirective,
    directives.ZoomDirective,
    directives.FullscreenDirective,
    directives.HasRightDirective,
    directives.RequiredModulesDirective,
    directives.ShowPricesDirective,
    LxmTextareaAutosize,
    LxmTreeCellDefDirective,
    LxmTreeColumnDefDirective,
    LxmTreeHeaderDefDirective,
    DataTableFilterDirective,
  ],
  providers: [
    CookieService,
    CurrencyPipe,
    BreadcrumbService,
    ActionHelper,
    PictogramHelper,
    pipes.PriceValuePipe,
    pipes.NumericValuePipe,
    pipes.FormDatePipe,
    pipes.TranslatedValuePipe,
    { provide: HTTP_INTERCEPTORS, useClass: ApiInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: JsonDateInterceptor, multi: true },
  ],
})
export class SharedModule {
  static forRoot(): ModuleWithProviders<SharedModule> {
    return {
      ngModule: SharedModule,
      providers: [
        LocaleService,
        {
          provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS,
          useValue: { useUtc: true },
        },
        { provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS },
        {
          provide: DateAdapter,
          useClass: MomentDateAdapter,
          deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
        },
      ],
    };
  }
}
