<ng-container [ngSwitch]="type">
  <ng-container *ngSwitchCase="'percent'" [ngSwitch]="theme">
    <ng-container *ngSwitchDefault>
      <svg-icon [key]="changeClass" fontSize="12px"></svg-icon>
      <span class="value">
        {{ value | percentValue }}
      </span>
    </ng-container>
    <ng-container *ngSwitchCase="'light'">
      <span class="value"> {{ prefix }} {{ value | percentValue }} </span>
      <svg-icon key="monochromeArrowDown" fontSize="12px"></svg-icon>
    </ng-container>
  </ng-container>

  <ng-container *ngSwitchCase="'absolute'">
    <span class="value"> {{ prefix }} {{ value | priceValue }} </span>
  </ng-container>
</ng-container>
