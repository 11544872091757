import {
  Component,
  OnInit,
  Host,
  ViewChild,
  ElementRef,
  AfterViewInit,
  OnDestroy,
  Input,
  Output,
  EventEmitter,
  Inject,
  ChangeDetectionStrategy
} from "@angular/core";
import { FormGroup, FormControl } from "@angular/forms";
import { Router, ActivatedRoute } from "@angular/router";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { debounceTime, map } from "rxjs/operators";
import {
  IRetailerOption,
  IOfferCard,
  IOfferCardFormData,
  IOfferCardDetail,
  IUser,
  IOfferRecipient,
  IAttachment,
  IUserAssistantDto
} from "src/app/models";
import { LxmMessage, DateHandler, LxmDialog } from "src/app/_helpers";
import {
  OfferService,
  AuthenticationService,
  SignalRService,
  TitleService,
  FileService,
  ExportService
} from "src/app/_services";
import { OfferLayoutComponent } from "src/app/views/offers/offer/offer.layout";
import {
  OfferType,
  SupplierOfferStatus,
  UserAction,
  OfferKind,
  LxmAppModule,
  OfferChangeType,
  UniqueCodeTypeOption
} from "src/app/enum";
import { AppState } from "src/app/state/app.state";
import { IClvDto } from "../product/product-info/product-info.model";
import { mergeMap } from "rxjs/operators";

import { RouteNavigator } from "src/app/_helpers/route.listener";
import { HubConnection, HubConnectionState } from "@microsoft/signalr";
import { OfferExportDialogComponent } from "./dialogs/export/export-offer.dialog";
import { Subscription, merge, of } from "rxjs";
import { MatSnackBarRef } from "@angular/material/snack-bar";
import { MessageComponent } from "src/app/components/_layout/messages/message/message.component";
import { RETAILER_ID } from "src/app/config";
import { TranslateService } from "@ngx-translate/core";
import { PipedriveService } from "src/app/_services/pipedrive.service";
import {
  AddFileDialog,
  IAddFileDialogData
} from "src/app/dialogs/add-file/add-file.dialog";
import { FilesListComponent } from "src/app/components/_partials/files-list/files-list.component";
import { Guid } from "src/app/util/Guid";
import { TranslatedValuePipe } from "src/app/pipes";
import { OfferExportType } from "src/app/enum/offer-export-type";
import { ORDERABLE_OFFER_TYPES } from "src/app/config";
import moment from "moment";
import { HttpEventType } from "@angular/common/http";
import { ImportsService } from "src/app/modules/imports/services/imports-service";
import { AddContactDialogComponent } from "src/app/modules/clients/cards";
import { appSettings } from "src/app/app.settings";
import { ClientsService } from "src/app/_services/clients.service";
import { CalendarGroupCampaignType } from "src/app/enum/calendar-group-campaign-type";

@Component({
  selector: "offer-card",
  templateUrl: "./offer.card.html",
  styleUrls: ["./offer.card.scss"],
  providers: [PipedriveService, TranslatedValuePipe]
})
export class OfferCardComponent implements OnInit, OnDestroy {
  private _msgRef: MatSnackBarRef<MessageComponent>;

  @ViewChild("download") private _download: ElementRef;

  @Input() public isEditable: boolean;

  @Output() editing = new EventEmitter();
  @Output() onFormChange = new EventEmitter();

  public translationsPath = "cards.offer.base_info";
  public OfferStatus = SupplierOfferStatus;
  public LxmAppModule = LxmAppModule;
  public UserAction = UserAction;
  public OfferType = OfferType;
  public procurements;

  public offerId: string;

  public fromCalendarEntry: boolean;

  private _offerTypeSortOrder = {
    [OfferType.MainAssortment.toString()]: 1,
    [OfferType.Campaign.toString()]: 2,
    [OfferType.PriceChanges.toString()]: 3,
    [OfferType.Logistics.toString()]: 4
  };

  public get productInstructions(): string[] {
    if (this.form?.value.offerType?.id == OfferType.Logistics) {
      return ["cards.offer.products.info.text1"];
    }

    let res = [
      "cards.offer.products.info.text1",
      "cards.offer.products.info.text2"
    ];

    if (this.retailerHasRPim) {
      res.push("cards.offer.products.info.remove_or_replace_text");
    }

    if (
      this.campaignType == CalendarGroupCampaignType.RetailSalesPriceDiscount
    ) {
      res.push("cards.offer.products.info.campaign_with_types_disclaimer");
    }

    return res;
  }

  public form: FormGroup;
  public edit = false;

  private _offerCard: IOfferCard;
  private _formData: IOfferCardFormData;
  private _snapshot: any;

  public retailers: IRetailerOption[];
  public period: string;

  public offer: IOfferCardDetail;
  public clientId: string;

  public sendingOffer = false;
  public saveLoading = false;
  public updatingPrices = false;
  public acceptingOffer = false;

  public sortOrderDefined = false;

  public recipientsOptions: IOfferRecipient[] = [];
  public assistantsOptions: IUserAssistantDto[] = [];
  public retailerCampaigns: IClvDto[] = [];

  private _hubConnection: HubConnection;
  private _signalRSubscription: Subscription;

  private _offerTitleChangeSubscription: Subscription;
  private _procurementProductsMapper: Subscription;

  public ownerOptions: IUser[] = [];
  public currencyOptions: IClvDto[] = [];

  public offerTypes: IClvDto[];

  public attachments: IAttachment[];

  public procurementProductsFormGroup: FormGroup = new FormGroup({});

  @ViewChild("filesList") filesList: FilesListComponent;

  public offerLastSentDate: moment.Moment;
  public offerLastModifiedDate: moment.Moment;

  constructor(
    @Host() private _page: OfferLayoutComponent,
    private _router: Router,
    private _route: ActivatedRoute,
    private _routeNavigator: RouteNavigator,
    private _dateHandler: DateHandler,
    private _offerService: OfferService,
    private _dialog: LxmDialog,
    private _message: LxmMessage,
    public appState: AppState,
    private _authService: AuthenticationService,
    private _clientsService: ClientsService,
    private _signalRService: SignalRService,
    private _translateService: TranslateService,
    private _pipedriveService: PipedriveService,
    private _translatedValuePipe: TranslatedValuePipe,
    private _titleService: TitleService
  ) {
    this.fromCalendarEntry = _route.snapshot.data.fromCalendarEntry;

    this._offerCard = _route.snapshot.data.offerCard as IOfferCard;
    this.clientId = _route.snapshot.params?.clientId;

    const { changes } = this._offerCard || {};

    if (changes?.length) {
      const offerLastModifiedDate = changes.find(
        (x: any) => x.type === OfferChangeType.Saved
      )?.createdAt;
      const offerLastSentDate = changes.find(
        (x: any) => x.type === OfferChangeType.SentToRetailer
      )?.createdAt;

      if (offerLastModifiedDate) {
        this.offerLastModifiedDate = moment(offerLastModifiedDate);
      }
      if (offerLastSentDate) {
        this.offerLastSentDate = moment(offerLastSentDate);
      }
    }

    this._formData = this._offerCard?.formData || _route.snapshot.data.formData;
    this.retailers = this._formData.retailers;

    this.offer = this._offerCard?.offerDetail as IOfferCardDetail;
    this.ownerOptions = this._formData.ownerOptions;

    this.attachments = this._offerCard?.attachments || [];

    const selectedOfferDetailRetailer = this.offer?.retailer; // selectedRetailer does not contain procurements
    const selectedRetailerId = selectedOfferDetailRetailer?.id;
    let selectedRetailer = selectedRetailerId
      ? this.retailers.find((x) => x.id == selectedRetailerId)
      : null;

    const selectedProcurement = this.offer?.procurement;
    const procurements =
      selectedOfferDetailRetailer?.procurements ||
      (selectedProcurement ? [selectedProcurement] : []);

    this.procurements = procurements;

    if (this.offer) {
      this.offerId = this.offer.id;

      this._signalRSubscription = this._signalRService.commonHub.subscribe(
        (connection) => {
          this._hubConnection = connection;
          if (connection?.state === HubConnectionState.Connected) {
            if (this._offerCard?.offerKind === OfferKind.SupplierOffer) {
              connection.send("viewSupplierOffer", this.offer.id);
            } else if (
              this._offerCard?.offerKind === OfferKind.ReceivingOffer
            ) {
              connection.send("viewReceivingOffer", this.offer.id);
            }

            connection.on("statusChanged", (data: any) => {
              this.offer.status = data.status;
            });

            connection.on("offerHasChanged", (data) => {
              if (this.offerId === data.offerId && data.read === false) {
                this._msgRef = this._message.ok({
                  message: "cards.offer.base_info.offer_changed",
                  indefinite: true,
                  buttonText: "action.reload_page",
                  buttonIcon: "generate",
                  buttonAction: () => {
                    this._router.routeReuseStrategy.shouldReuseRoute = () =>
                      false;
                    this._router.onSameUrlNavigation = "reload";
                    this._router.navigate(["/offers", this.offerId]);
                    this._msgRef.dismiss();
                  }
                });
              }
            });
          }
        }
      );
    } else if (this.clientId) {
      const predefinedRetailer = this.retailers.find(
        (x) => x.id === this.clientId
      );
      if (predefinedRetailer) {
        selectedRetailer = predefinedRetailer;
      }
    }

    this.form = new FormGroup({
      name: new FormControl(this.offer?.name),
      retailer: new FormControl(selectedRetailer),
      currency: new FormControl(this.offer?.currency),
      recipients: new FormControl(
        this._formatRecipientOptions(this.offer?.recipients)
      ),
      assistants: new FormControl(
        this._formatAssistantsOptions(
          this.getAllAssistants(this.offer?.recipients)
        )
      ),
      offerType: new FormControl(),
      retailerCampaign: new FormControl(this.offer?.retailerCampaign),
      dateFrom: new FormControl(this.offer?.from?.editableDate),
      dateTo: new FormControl(this.offer?.to?.editableDate),
      description: new FormControl(this.offer?.description),
      products: new FormControl(
        this._offerCard?.products ||
          this._formData.products?.map((x) =>
            Object.assign({}, x, { id: null, isNew: true })
          ) ||
          []
      ),
      offerOwner: new FormControl(this._offerCard?.owner),
      procurement: new FormControl(selectedProcurement),
      includeProductsPdf: new FormControl(
        this.offer?.includeProductsPdf ?? false
      ),
      canOrder: new FormControl(
        this.offer?.canOrder ?? this.canCreateSalesOrders
      ),
      calendarEntry: new FormControl(this.offer?.offerCalendarEntryId)
    });

    this._updateCurrencyOptions(selectedRetailer);
    this._updateRecipientsOptions(selectedRetailer);
    this._updateRetailerCampaigns(selectedRetailer);
    this._updateOfferTypes(selectedRetailer);

    if (this.procurements?.length) {
      this._updateProcurements(procurements, selectedProcurement);
    }

    this.form
      .get("offerType")
      .setValue(
        this.offer?.offerType ||
          this.offerTypes.find(
            (x) => x.id === OfferType.MainAssortment.toString()
          ),
        {
          emitEvent: false
        }
      );

    this.form.get("retailer").valueChanges.subscribe((val) => {
      this._updateCurrencyOptions(val);
      this._updateRecipientsOptions(val);
      this.form.get("recipients").setValue([]);

      this._updateRetailerCampaigns(val);
      this.form.get("retailerCampaign").setValue(null);

      this._updateOfferTypes(val);
    });

    this.form.get("recipients").valueChanges.subscribe((val) => {
      this._updateAssistantsOptions(val);
    });

    this.form.get("offerType").valueChanges.subscribe((val) => {
      const retailerId = this.form.value.retailer?.id;
      if (val?.id == OfferType.Procurement && retailerId) {
        this._offerService
          .getActiveProcurements(retailerId)
          .subscribe((procurements) => {
            this._updateProcurements(procurements);
          });
      }
    });

    this.form.get("procurement").valueChanges.subscribe((val) => {
      this._setProcurementControls(val, this.form.get("products").value);
    });
  }

  public get canDelete() {
    if (!this.offer) {
      return false;
    }

    if (
      this.offer.status === SupplierOfferStatus.Created ||
      this.offer.status === SupplierOfferStatus.Expired ||
      this.offer.status === SupplierOfferStatus.Rejected
    ) {
      return true;
    }

    return false;
  }

  public get canAccept() {
    if (!this.offer) {
      return false;
    }

    if (!this._authService.hasRight([UserAction.ConfirmSupplierOffers])) {
      return false;
    }

    if (
      this.offer.status === SupplierOfferStatus.Sent ||
      this.offer.status === SupplierOfferStatus.Negotiating
    ) {
      return true;
    }

    return false;
  }

  public get canChangeProcurement() {
    if (!this.offer) {
      return true;
    }

    if (this.offer.status === SupplierOfferStatus.Created) {
      return true;
    }

    if (!this.offer.procurement) {
      return true;
    }

    return false;
  }

  public get isOfferModifiedAfterSent() {
    if (!this.offerLastSentDate || !this.offerLastModifiedDate) {
      return false;
    }
    return this.offerLastModifiedDate.isAfter(this.offerLastSentDate);
  }

  public get offerType() {
    return this.form.get("offerType").value;
  }

  public get offerTypeId() {
    return this.form.get("offerType").value?.id;
  }

  public get isRequestedOffer(): boolean {
    return this.offer?.isRequestedOffer || false;
  }

  public get canEditRetailer(): boolean {
    if (
      this.isRequestedOffer ||
      this.fromCalendarEntry ||
      this.hasPredefinedRetailer
    ) {
      return false;
    }

    return (
      (this.offer?.status ?? SupplierOfferStatus.Created) ==
        SupplierOfferStatus.Created || this.offer?.status === 0
    );
  }

  public get canEditCurrency(): boolean {
    if (this.currencyOptions.length < 2) {
      return false;
    }

    return (
      (this.offer?.status ?? SupplierOfferStatus.Created) ==
        SupplierOfferStatus.Created || this.offer?.status === 0
    );
  }

  public get hasExportFilesFromDifferentGs1Groups(): boolean {
    return this._offerCard?.hasExportFilesFromDifferentGs1Groups;
  }

  public set hasExportFilesFromDifferentGs1Groups(value: boolean) {
    if (!this._offerCard) return;
    this._offerCard.hasExportFilesFromDifferentGs1Groups = value;
  }

  public get selectedRecipients() {
    return this.form.get("recipients").value;
  }

  public get selectedAssistants() {
    return this.form.get("assistants").value;
  }

  public get isCustomRecipientDisabled() {
    const retailer = this.form.get("retailer").value;
    return retailer?.isCustomRecipientForOfferDisabled || false;
  }

  public get hasAutomaticConfirmation() {
    const retailer = this.form.get("retailer").value;
    return retailer?.hasAutomaticOfferConfirmation || false;
  }

  public get isSupplierOfferConfirmDisabled() {
    const retailer = this.form.get("retailer").value;
    return retailer?.isSupplierOfferConfirmDisabled || false;
  }

  public get canCreateSalesOrders() {
    return this._authService.hasModule(LxmAppModule.CreateSalesOrders);
  }

  public get isOfferTypeOrderable() {
    return ORDERABLE_OFFER_TYPES.includes(this.offerTypeId);
  }

  public get canEdit() {
    return (
      this.isEditable &&
      this._authService.hasRight([UserAction.ManageSupplierOffers])
    );
  }

  public get offerTitle() {
    const offerName = this.form.get("name").value;

    if (typeof offerName === "string" && offerName.length > 0) {
      return offerName;
    }

    if (!this.offerId) {
      return this._translateService.instant(
        this.translationsPath + ".title_new_offer"
      );
    } else {
      return this._translateService.instant(
        this.translationsPath + ".title_edit_offer"
      );
    }
  }

  public get isProSelected() {
    return this.form.get("retailer").value?.pro;
  }

  public get retailerHasRPim() {
    return this.form.get("retailer").value?.hasRPim;
  }

  public get isExportXlsEnabled() {
    const retailer = this.form.get("retailer").value;
    if (retailer.isClient) {
      const xlsExportTypes = [
        OfferExportType.LxmCrmOfferEng,
        OfferExportType.LxmCrmOfferEst,
        OfferExportType.LxmCrmOfferSimpleEng,
        OfferExportType.LxmCrmOfferSimpleEst,
        OfferExportType.SupplierProductsExport
      ];
      return xlsExportTypes.includes(retailer.offerExportType);
    } else {
      return true;
    }
  }

  public get isExportPdfEnabled() {
    const retailer = this.form.get("retailer").value;
    if (retailer.isClient) {
      const pdfExportTypes = [
        OfferExportType.OfferPdf,
        OfferExportType.OfferAndProductsPdf
      ];
      return pdfExportTypes.includes(retailer.offerExportType);
    } else {
      return this.appState.hasModule(LxmAppModule.Pdf);
    }
  }

  public get procurement() {
    return this.form.get("procurement").value;
  }

  public get isProcurement() {
    return this.offerTypeId === OfferType.Procurement;
  }

  public get offerDateFrom() {
    if (this.isProcurement) {
      return this.procurement?.validFrom;
    }
    return this.form.get("dateFrom").value;
  }

  public get offerSupplyPeriodFrom() {
    return this.procurement?.supplyFrom;
  }

  public get offerSupplyPeriodTo() {
    return this.procurement?.supplyTo;
  }

  public get procurementDescription() {
    return this._translatedValuePipe.transform(this.procurement?.description);
  }

  public get offerDescription() {
    return this.form.get("description").value;
  }

  public get offerDateTo() {
    if (this.offerTypeId === OfferType.Procurement) {
      return this.procurement?.validTo;
    }
    return this.form.get("dateTo").value;
  }

  public get offerProducts() {
    return this._offerCard?.products;
  }

  public get hasPredefinedRetailer() {
    return this.clientId && this.form.get("retailer").value;
  }

  public get offerCurrency() {
    return this.form.get("currency").value;
  }

  public get canIncludeProductsPdf() {
    return (
      this.appState.hasModule(LxmAppModule.Pdf) &&
      this.form.get("retailer").value?.offerExportType !=
        OfferExportType.OfferAndProductsPdf &&
      (this.offerTypeId === OfferType.MainAssortment || this.isProcurement) &&
      ((this.offer?.status ?? SupplierOfferStatus.Created) ==
        SupplierOfferStatus.Created ||
        this.offer?.status === 0)
    );
  }

  public get selectedRetailer() {
    return this.form.get("retailer").value;
  }

  public get showOfferDateFromField() {
    return this.form.value.offerType?.id == OfferType.PriceChanges;
  }

  public get showOfferSupplyPeriodField() {
    return (
      this.isProcurement &&
      (this.offerSupplyPeriodFrom || this.offerSupplyPeriodTo)
    );
  }

  public get showOfferPeriodField() {
    return (
      this.form.value.offerType?.id != OfferType.Logistics &&
      !this.showOfferDateFromField
    );
  }

  public get showAddNewContactButton() {
    const hasModule = this._authService.hasModule(LxmAppModule.SellerCrm);
    const hasRight = this._authService.hasRight([
      UserAction.ManageClients,
      UserAction.ManageOthersClients
    ]);
    return hasModule && hasRight && this.selectedRetailer?.isClient;
  }

  public get isCampaign() {
    return this.offerTypeId === OfferType.Campaign;
  }

  public get campaignType() {
    return (
      this.offer?.campaignType ?? CalendarGroupCampaignType.BuyInPriceDiscount
    );
  }

  public sortClient(a: IRetailerOption, b: IRetailerOption): number {
    return (a.pro ? 1 : 0) + a.name > (b.pro ? 1 : 0) + b.name ? 1 : -1;
  }

  public onOwnerChange($event) {
    this._offerService.setOwner(this.offerId, $event.id).subscribe(
      (res) => {
        this._message.ok({
          message: "cards.offer.base_info.owner_set_successfully"
        });
      },
      (err) => {
        this._message.error({
          message: "cards.offer.base_info.setting_owner_failed"
        });
      }
    );
  }

  private _updateProcurements(
    procurements = this.procurements,
    selected = this.form.get("procurement").value
  ) {
    if (!procurements.length) {
      return;
    }

    const selection = selected || procurements[0];
    this._setProcurementControls(selection, this.form.get("products").value);
    this.procurements = procurements;
    this.form.get("procurement").setValue(selection);

    if (selection.canOrder) {
      this.form.get("canOrder").setValue(true);
    }
  }

  private _setProcurementControls(procurement, products?) {
    if (this._procurementProductsMapper) {
      this._procurementProductsMapper.unsubscribe();
    }
    const fg = new FormGroup({});

    if (procurement?.items.length) {
      for (let i = 0; i < procurement.items.length; i++) {
        const procurementItemId = procurement.items[i].id;
        const controlProducts = products?.length
          ? products.filter((x) => x.procurementItemId === procurementItemId)
          : [];
        fg.addControl(procurementItemId, new FormControl(controlProducts));
      }
    }

    this._procurementProductsMapper = fg.valueChanges.subscribe((val) => {
      const mappedProducts = [].concat.apply(
        [],
        Object.keys(val).map((x) => {
          let products = val[x];
          for (let product of products) {
            product.procurementItemId = x;
          }
          return products;
        })
      );

      this.form.get("products").setValue(mappedProducts, { emitEvent: false });
    });

    this.procurementProductsFormGroup = fg;
  }

  private _updateCurrencyOptions(selectedRetailer: IRetailerOption) {
    if (!selectedRetailer) {
      this.currencyOptions = [];
      this.form.get("currency").setValue(null);
      return;
    }

    if (selectedRetailer.isClient) {
      this.currencyOptions = this._formData.currencies;
    } else {
      this.currencyOptions = this._formData.currencies.filter(
        (x) => selectedRetailer.acceptedCurrencies?.indexOf(x.value) >= 0
      );
    }

    const currentValue = this.form.get("currency").value?.id;
    this.form
      .get("currency")
      .setValue(
        this.currencyOptions.find((x) => x.id === currentValue) ||
          this.currencyOptions.find(
            (x) => x.value == selectedRetailer.defaultCurrency
          )
      );
  }

  private _updateRecipientsOptions(
    selectedRetailer: IRetailerOption = this.selectedRetailer
  ) {
    const retailerId = selectedRetailer?.id;
    this.recipientsOptions = !retailerId
      ? []
      : this._formatRecipientOptions(
          this.retailers.find((x) => x.id === retailerId)?.purchaseManagers
        );
  }

  private _updateAssistantsOptions(
    selectedPurchaseManagers: IOfferRecipient[]
  ) {
    if (selectedPurchaseManagers) {
      let managersAssistants = this.getAllAssistants(selectedPurchaseManagers);
      this.assistantsOptions = !managersAssistants
        ? []
        : this._formatAssistantsOptions(managersAssistants);

      this.form.get("assistants").setValue(this.assistantsOptions);
    } else {
      this.assistantsOptions = [];
    }
  }

  private getAllAssistants(selectedPurchaseManagers: IOfferRecipient[]) {
    let managersAssistants = [];
    if (selectedPurchaseManagers) {
      selectedPurchaseManagers.forEach((manager) => {
        if (manager.assistants) {
          manager.assistants.forEach((assistant) => {
            if (!managersAssistants.includes(assistant)) {
              managersAssistants.push(assistant);
            }
          });
        }
      });

      return managersAssistants;
    }

    return [];
  }

  private _updateRetailerCampaigns(selectedRetailer: IRetailerOption) {
    const retailerId = selectedRetailer?.id;
    this.retailerCampaigns = !retailerId
      ? []
      : this.retailers.find((x) => x.id === retailerId)?.campaigns || [];
  }

  private _updateOfferTypes(selectedRetailer: IRetailerOption) {
    if (!selectedRetailer) {
      this.offerTypes = this._formData.offerTypes.sort((a, b) =>
        this._sortOfferTypeFn(a, b)
      );
      return;
    }

    let offerTypes = this._formData.offerTypes
      .filter((x) => selectedRetailer.offerTypes.includes(x.id))
      .sort((a, b) => this._sortOfferTypeFn(a, b));

    if (selectedRetailer.isClient) {
      offerTypes = offerTypes.filter((x: any) => x.id !== OfferType.Campaign);
    }

    this.offerTypes = offerTypes;

    const selectedOfferTypeId = this.form.value.offerType?.id;
    if (!selectedRetailer.offerTypes.includes(selectedOfferTypeId)) {
      this.form.get("offerType").setValue(this.offerTypes[0]);
    } else if (this.offerTypeId === OfferType.Procurement) {
      this.form.get("offerType").setValue(this.offerType); // Trigger subscriber
    }
  }

  private _sortOfferTypeFn(a, b) {
    if (this._offerTypeSortOrder[a.id] < this._offerTypeSortOrder[b.id]) {
      return -1;
    }
    if (this._offerTypeSortOrder[a.id] > this._offerTypeSortOrder[b.id]) {
      return 1;
    }
    return 0;
  }

  private _formatRecipientOptions(options: any[]) {
    if (!options) {
      return [];
    }
    return options.map((x) =>
      Object.assign({}, x, {
        label: x.name
          ? x.email
            ? `${x.name} (${x.email})`
            : `${x.name} (${this._translateService.instant("global.no_email")})`
          : x.email
      })
    );
  }

  private _formatAssistantsOptions(options: any[]) {
    if (!options) {
      return [];
    }
    return options.map((x) =>
      Object.assign({}, x, {
        label: x.fullName
          ? x.email
            ? `${x.fullName} (${x.email})`
            : `${x.fullName} (${this._translateService.instant("global.no_email")})`
          : x.email
      })
    );
  }

  private _setOfferTitle() {
    const form = this.form.value;
    const { retailer } = form;
    if (this.offerTitle) {
      const retailerShortName = retailer?.shortName;
      const title = retailerShortName
        ? retailerShortName + " - " + this.offerTitle
        : this.offerTitle;
      this._titleService.setTitle(title);
    }
  }

  ngOnInit() {
    if (!this.offerId) {
      this.edit = true;
      this.editing.emit(this.edit);
    }
    this._snapshot = this.form.value;
    merge(
      this.form.get("dateFrom").valueChanges,
      this.form.get("dateTo").valueChanges
    ).subscribe(() => {
      const begin = this.form.get("dateFrom").value;
      const end = this.form.get("dateTo").value;
      const period = this._dateHandler.rangePeriodChecker(begin, end);
      this.period = period;
    });

    this.form.valueChanges.subscribe((x) => {
      this.onFormChange.emit(x);
    });

    this._pipedriveService.appendLeadbooster(
      "e15ac60b-3def-4c17-9dc3-0517e884b88b"
    );

    this._setOfferTitle();
    this._offerTitleChangeSubscription = merge(
      this.form.get("retailer").valueChanges,
      this.form.get("name").valueChanges
    )
      .pipe(debounceTime(200))
      .subscribe((val) => {
        this._setOfferTitle();
      });
  }

  public setDatePeriod(period: string) {
    const dateFromControl = this.form.get("dateFrom");
    const dateToControl = this.form.get("dateTo");

    const x = this._dateHandler.getPeriod(period, dateFromControl.value);
    dateFromControl.setValue(x.begin);
    dateToControl.setValue(x.end);
    this.period = period;
  }

  public openImportProductDialog() {
    this._dialog.open(
      SupplierOfferImportProductDialog,
      {
        data: { offerId: this.offerId }
      },
      (res) => {
        if (res) {
          this.save(() => {
            window.location.reload();
          });
        }
      }
    );
  }

  public backToList() {
    this._routeNavigator.toPreviousRoute({ fallback: "/offers" });
  }

  public exportXls() {
    this._dialog.open(OfferExportDialogComponent, {
      resolve: () =>
        this._offerService
          .exportXls(this.offerId)
          .pipe(
            mergeMap(async (res) => {
              const blob = await this._offerService
                .downloadXls(res.id)
                .toPromise();
              return {
                fileName: res.fileName,
                blob: blob
              };
            })
          )
          .pipe(
            map((res) => {
              const url = window.URL.createObjectURL(res.blob);

              const link = this._download.nativeElement;
              link.href = url;
              link.download = res.fileName;
              link.click();

              window.URL.revokeObjectURL(url);
            })
          )
    });
  }

  public exportPdf() {
    this._dialog.open(OfferExportDialogComponent, {
      resolve: () =>
        this._offerService
          .exportPdf(this.offerId)
          .pipe(
            mergeMap(async (res) => {
              const blob = await this._offerService
                .downloadPdf(res.id)
                .toPromise();
              return {
                fileName: res.fileName,
                blob: blob
              };
            })
          )
          .pipe(
            map((res) => {
              const url = window.URL.createObjectURL(res.blob);

              const link = this._download.nativeElement;
              link.href = url;
              link.download = res.fileName;
              link.click();

              window.URL.revokeObjectURL(url);
            })
          )
    });
  }

  public clone() {
    this._router.navigate(["/offers/clone", this.offerId]);
  }

  public toggleEdit() {
    if (this.edit) {
      this._revertToInitialData();
    } else {
      this._saveInitialData();
    }
    this.edit = !this.edit;
    this.editing.emit(this.edit);
  }

  public getOfferStatusProgress(status: SupplierOfferStatus) {
    return this._offerService.getSendingOfferStatusProgress(status);
  }

  private _productMapper(x, index) {
    let product = {
      id: x.id,
      status: x.status,
      productId: x.productId,
      display: x.display || false,
      availableFrom: x.availableFrom,
      salePrice: x.salePrice,
      suggestedRetailPrice: x.suggestedRetailPrice,
      listPrice: null,
      sortOrderDefined: this.sortOrderDefined,
      sortOrder: index,
      orderDetails: x.orderDetails,
      replacementForProductId: x.replacementForProductId,
      isSuspended: x.isSuspended
    };

    switch (this.offerTypeId) {
      case OfferType.PriceChanges:
        Object.assign(product, {
          priceChangeDate: x.priceChangeDate,
          priceChangeReasonId: x.priceChangeReason?.id,
          priceChangeNote: x.priceChangeNote,
          salePrice: x.salePricePriceChanges
            ? x.salePricePriceChanges
            : x.salePrice,
          listPrice: x.editableListPrice
        });
        break;
      case OfferType.Procurement:
        Object.assign(product, {
          procurementItemId: x.procurementItemId,
          procurementDetails: x.procurementDetails
        });
        break;
      case OfferType.Campaign:
        Object.assign(product, {
          salePrice: x.salePriceCampaign ? x.salePriceCampaign : x.salePrice,
          campaignTypeId: x.campaignType?.id,
          campaignInfo: x.campaignInfo
            ? {
                limit: x.campaignInfo.limit,
                netting: x.campaignInfo.netting || false,
                addressed: x.campaignInfo.addressed || false,
                withAd: x.campaignInfo.withAd || false,
                marketingFee: x.campaignInfo.marketingFee,
                invoiceRecipientId: x.campaignInfo.invoiceRecipient?.id,
                storeCount: x.campaignInfo.storeCount,
                displaySize: x.campaignInfo.displaySize,
                returnableItems: x.campaignInfo.returnableItems,
                comments: x.campaignInfo.comments,
                campaignNr: x.campaignInfo.campaignNr,
                campaignPriceValidFrom: x.campaignPriceValidFrom
              }
            : null
        });
        break;
    }

    return product;
  }

  private _serializeSaveData(f = this.form.value) {
    let data: any = {
      retailerId: f.retailer?.id,
      currencyId: f.currency?.id,
      recipients: f.recipients,
      offerTypeId: f.offerType?.id,
      retailerCampaignId: f.retailerCampaign?.id,
      name: f.name,
      description: f.description,
      validFrom: f.dateFrom,
      validTo: f.dateTo,
      canOrder: this.isOfferTypeOrderable ? f.canOrder : false,
      offerCalendarEntryId: f.calendarEntry,
      products: f.products
        .filter((x) => !x.isDeleted)
        .map((product, index) => this._productMapper(product, index))
    };

    switch (this.offerTypeId) {
      case OfferType.Procurement:
        Object.assign(data, {
          procurementId: this.procurement?.id
        });
        break;
      case OfferType.PriceChanges:
        data.validTo = null;
        break;
      case OfferType.Campaign:
        Object.assign(data, {
          campaignType: this.campaignType
        });
        break;
    }

    if (this.canIncludeProductsPdf) {
      data.includeProductsPdf = f.includeProductsPdf;
    }

    return data;
  }

  private setDefaultSalePrice() {
    const products = this.form.get("products").value;

    Object.keys(products).forEach((x) => {
      const product = products[x];
      if (isNaN(product.salePrice) || product.salePrice < 0) {
        product.discount = 0;
        product.salePrice = product.listPrice;
      }
    });

    this.form.patchValue({
      products: products
    });
  }

  public save(callback = null) {
    this.saveLoading = true;
    this.setDefaultSalePrice();

    const data = this._serializeSaveData();
    if (callback) {
      data.isImport = true;
    }

    const save = this.offerId
      ? this._offerService.saveOffer(this.offerId, data)
      : this._offerService.createOffer(
          Object.assign({}, data, {
            attachments: this.attachments.map((x) => ({
              name: x.name,
              description: x.description,
              fileId: x.fileId
            }))
          })
        );

    save.result(
      this.form,
      (res) => {
        this.offerLastModifiedDate = moment();

        this._message.ok({
          message: "common.message.offer_saved_successfully"
        });

        if (this.offerId) {
          this.form.patchValue({
            //products: res.products,
            recipients: this._formatRecipientOptions(res.offerDetail.recipients)
          });

          this._saveInitialData();
        } else {
          this._router.navigate(["/offers", res], { replaceUrl: true });
        }

        this.hasExportFilesFromDifferentGs1Groups =
          res.hasExportFilesFromDifferentGs1Groups;

        this.edit = false;
        this.editing.emit(this.edit);

        this.saveLoading = false;

        if (callback) {
          callback();
        }
      },
      (error) => {
        this._message.error({
          messages: ["common.error.save_failed", error.validationSummary]
        });
        this.saveLoading = false;
      }
    );
  }

  public addNewRecipient = (email) => {
    return new Promise((resolve) => {
      const newRecipient = {
        id: null,
        name: null,
        email: email,
        label: email
      };
      resolve(newRecipient);
    });
  };

  public openAddNewContactDialog() {
    const selectedClientId = this.selectedRetailer?.id;

    if (selectedClientId) {
      this._clientsService.getClient(selectedClientId).result(null, (res) => {
        this._dialog.open(
          AddContactDialogComponent,
          {
            ...appSettings.DIALOG_SIZES.M,
            data: {
              clientId: selectedClientId,
              formData: res?.formData,
              edit: true
            }
          },
          (res) => {
            if (res) {
              const { email, userId, name } = res || {};

              const retailerOptionsRef = this.retailers.find(
                (x) => x.id === selectedClientId
              );

              const newRecipient = {
                id: null,
                userId: userId,
                email: email,
                name: name,
                assistants: []
              };

              if (Array.isArray(retailerOptionsRef?.purchaseManagers)) {
                retailerOptionsRef.purchaseManagers.push(newRecipient);
              } else {
                retailerOptionsRef.purchaseManagers = [newRecipient];
              }

              this._updateRecipientsOptions();
            }
          }
        );
      });
    }
  }

  public isRowInvalid(row: any): boolean {
    const listPriceRequired =
      this.offerTypeId == OfferType.MainAssortment && row.listPrice === null;
    return listPriceRequired || !row.isValid;
  }

  public get isAnyRowInvalid() {
    return this.form.get("products").value?.some((x) => this.isRowInvalid(x));
  }

  public get sendOfferRequirements() {
    const data = this._serializeSaveData();

    const { products } = data || {};

    const offerHasProducts = products?.length ? true : false;
    const productsHaveSalePrice = offerHasProducts
      ? products.some((x) => isNaN(x.salePrice) || x.salePrice < 0)
        ? false
        : true
      : false;

    const requirements = {
      offerHasProducts: offerHasProducts,
      productsHaveSalePrice: productsHaveSalePrice
    };

    return requirements;
  }

  public openMissingRequirementsDialog(
    requirements = this.sendOfferRequirements
  ) {
    const { offerHasProducts, productsHaveSalePrice } = requirements;

    const noProductsTemplate = `
      <p>
        <span class="bold">${this._translateService.instant("cards.offers.offer_dialogs.offer_supplier_offer_saved_dialog_send.invalid_products_warning")}</span> 
        ${this._translateService.instant("cards.offers.offer_dialogs.offer_supplier_offer_saved_dialog_send.missing_requirements_no_products_text")}</p>
      <p>
    `;

    const noSalePriceTemplate = `
      <p>
        <span class="bold">${this._translateService.instant("cards.offers.offer_dialogs.offer_supplier_offer_saved_dialog_send.invalid_products_warning")}</span> 
        ${this._translateService.instant("cards.offers.offer_dialogs.offer_supplier_offer_saved_dialog_send.missing_requirements_no_sale_price_text")}</p>
      <p>
    `;

    this._dialog.confirm(
      {
        image: "warning-modal-image",
        template: `
      <p class="dialog-title mt5">
        ${this._translateService.instant("cards.offers.offer_dialogs.offer_supplier_offer_saved_dialog_send.missing_requirements_title")}</p>
      <p>
      ${!offerHasProducts ? noProductsTemplate : ""}
      ${offerHasProducts && !productsHaveSalePrice ? noSalePriceTemplate : ""}  
      `,
        yes: "cards.offers.offer_dialogs.offer_supplier_offer_saved_dialog_send.cancel",
        showNo: false
      },
      (res) => {
        return;
      }
    );
  }

  // public get productAcExcludeIds() {
  //   return [];
  //   const { products } = this.form.value;
  //   const uniqueIds = new Set();

  //   for (const product of products) {
  //     uniqueIds.add(product.productId);
  //   }

  //   const ids = Array.from(uniqueIds);
  //   return  ids;
  // }

  public send() {
    const requirements = this.sendOfferRequirements;
    const { offerHasProducts, productsHaveSalePrice } = requirements;

    const { products } = this.form.value;

    const getDuplicateProducts = () => {
      const seen = new Set();
      const duplicates = [];

      for (const product of products) {
        const id = product.productId;
        if (seen.has(id)) {
          duplicates.push(product);
        } else {
          seen.add(id);
        }
      }

      return duplicates;
    };

    const duplicateProducts = getDuplicateProducts();

    if (duplicateProducts.length > 0) {
      const firstDuplicateProduct = duplicateProducts[0];
      this._message.error({
        message: this._translateService.instant(
          "cards.offers.errors.duplicate_product",
          {
            name: this._translatedValuePipe.transform(
              firstDuplicateProduct.name
            ),
            ean: firstDuplicateProduct.ean
          }
        )
      });
      return;
    }

    if (
      this.offerType?.id !== OfferType.Logistics &&
      (!offerHasProducts || !productsHaveSalePrice)
    ) {
      this.openMissingRequirementsDialog(requirements);
      return;
    }

    let confirmOptions = {
      image: "send-proposals-modal-image",
      yes: "cards.offers.offer_dialogs.offer_supplier_offer_saved_dialog_send.confirm",
      no: "cards.offers.offer_dialogs.offer_supplier_offer_saved_dialog_send.cancel",
      template: null,
      swapButtons: false,
      showNo: true,
      showYes: true
    };

    if (this.isAnyRowInvalid || this.hasExportFilesFromDifferentGs1Groups) {
      const hasMandatoryProductValidation =
        this.form.value.retailer?.hasMandatoryProductValidation;

      confirmOptions.image = "warning-modal-image";

      if (hasMandatoryProductValidation) {
        confirmOptions.showYes = false;
        confirmOptions.template = `
        <p class="dialog-title mt5">${this._translateService.instant("cards.offers.offer_dialogs.offer_supplier_offer_saved_dialog_send.mandatory_validation.title")}</p>
        <p>
          <span class="bold">${this._translateService.instant("cards.offers.offer_dialogs.offer_supplier_offer_saved_dialog_send.mandatory_validation.text")}</span> 
        </p> 
        <ul>
          <li>${this._translateService.instant("cards.offers.offer_dialogs.offer_supplier_offer_saved_dialog_send.mandatory_validation.instruction_1")}</li>
          <li>${this._translateService.instant("cards.offers.offer_dialogs.offer_supplier_offer_saved_dialog_send.mandatory_validation.instruction_2")}</li>
        </ul>`;
      } else {
        if (confirmOptions.showYes && this.isOfferModifiedAfterSent) {
          confirmOptions.yes =
            "cards.offers.offer_dialogs.offer_supplier_offer_saved_dialog_send.confirm_changes";
        }
        confirmOptions.template = `
        <p class="dialog-title mt5">${this._translateService.instant(this.isOfferModifiedAfterSent ? "cards.offers.offer_dialogs.offer_supplier_offer_saved_dialog_send.text_changes" : "cards.offers.offer_dialogs.offer_supplier_offer_saved_dialog_send.text")}</p>
        <p>
          <span class="bold">${this._translateService.instant("cards.offers.offer_dialogs.offer_supplier_offer_saved_dialog_send.invalid_products_warning")}</span> 
          ${this._translateService.instant(this.isOfferModifiedAfterSent ? "cards.offers.offer_dialogs.offer_supplier_offer_saved_dialog_send.invalid_products_text1_changes" : "cards.offers.offer_dialogs.offer_supplier_offer_saved_dialog_send.invalid_products_text1")}
        </p> 
        <p>
          ${this._translateService.instant(this.isOfferModifiedAfterSent ? "cards.offers.offer_dialogs.offer_supplier_offer_saved_dialog_send.invalid_products_text2_changes" : "cards.offers.offer_dialogs.offer_supplier_offer_saved_dialog_send.invalid_products_text2")}
        </p>`;
      }
    } else {
      confirmOptions.image = "ok-modal-image";
      let confirmMessageText =
        "cards.offers.offer_dialogs.offer_supplier_offer_saved_dialog_send.text";
      if (this.hasExportFilesFromDifferentGs1Groups) {
        const retailerId = this.form.value.retailer?.id;
        if (retailerId == RETAILER_ID.KAUBAMAJA) {
          confirmMessageText =
            "cards.offers.offer_dialogs.offer_supplier_offer_saved_dialog_send.alcohol_products_text";
        } else if (retailerId == RETAILER_ID.PRISMA) {
          confirmMessageText =
            "cards.offers.offer_dialogs.offer_supplier_offer_saved_dialog_send.fresh_food_products_text";
        } else if (retailerId == RETAILER_ID.MAXIMA) {
          confirmMessageText =
            "cards.offers.offer_dialogs.offer_supplier_offer_saved_dialog_send.drinks_products_text";
        }
      }
      confirmOptions.template = `
        <p class="dialog-title mt5 mb0">${this._translateService.instant("cards.offers.offer_dialogs.offer_supplier_offer_saved_dialog_send.title")}</p>
        <p>
          ${this._translateService.instant(confirmMessageText)}
        </p>
        `;
    }

    this._dialog.confirm(confirmOptions, (ref) => {
      this.sendingOffer = true;

      this._offerService.sendOffer(this.offerId).result(
        null,
        (res) => {
          this.offerLastSentDate = moment();
          this.sendingOffer = false;

          this._message.ok({
            message: "cards.offer.base_info.send_successful"
          });

          if (
            this.form.value.retailer?.id == RETAILER_ID.RIMI_EST &&
            this.form.value.offerType?.id == OfferType.Campaign
          ) {
            window.open("https://irimi.rimibaltic.com/", "_blank");
          }

          this.setCurrentUserAsOfferOwner();

          ref.close();
        },
        (error) => {
          this.sendingOffer = false;
          this._message.error({
            messages: [
              "cards.offer.base_info.send_failed",
              error.validationSummary
            ]
          });
        }
      );
    });
  }

  private setCurrentUserAsOfferOwner() {
    let currentOwner = this.form.get("offerOwner").value;
    if (!currentOwner) {
      const currentUser = this.ownerOptions.find(
        (x) => x.id == this.appState.user.id
      );
      this.form.get("offerOwner").setValue(currentUser);
    }
  }

  public downloadImages() {
    this._dialog.open(OfferExportDialogComponent, {
      resolve: () =>
        this._offerService
          .exportProductImages(this.offerId)
          .pipe(
            mergeMap(async (res) => {
              const blob = await this._offerService
                .downloadProductImages(res.id)
                .toPromise();
              return {
                fileName: res.fileName,
                blob: blob
              };
            })
          )
          .pipe(
            map((res) => {
              const url = window.URL.createObjectURL(res.blob);

              const link = this._download.nativeElement;
              link.href = url;
              link.download = res.fileName;
              link.click();

              window.URL.revokeObjectURL(url);
            })
          )
    });
  }

  private _revertToInitialData() {
    this.form.setValue(this._snapshot);
  }

  private _saveInitialData() {
    this._snapshot = this.form.value;
  }

  public deleteOffer() {
    this._dialog.confirm(
      {
        title: "cards.offers.offer_dialogs.delete_offer.title",
        message: "cards.offers.offer_dialogs.delete_offer.text"
      },
      () => {
        this._offerService.deleteOffer(this.offerId).result(null, () => {
          this._message.ok({
            message: "cards.offers.offer_dialogs.delete_offer.offer_deleted"
          });

          this._router.navigate(["/offers"]);
        });
      }
    );
  }

  public accept() {
    let confirmOptions = {
      image: "confirm-offer-supplier-image",
      yes: "cards.offers.offer_dialogs.supplier_offer_accept.action.confirm",
      no: "cards.offers.offer_dialogs.supplier_offer_accept.action.cancel",
      template: `
      <p class="dialog-title mt5">${this._translateService.instant("cards.offers.offer_dialogs.supplier_offer_accept.text")}</p> 
      <p>
        <span class="bold">${this._translateService.instant("global.attention_uppercase")}</span>
        <span>${this._translateService.instant("cards.offers.offer_dialogs.supplier_offer_accept.attention_text")}</span>
      </p>
      `,
      swapButtons: false
    };

    this._dialog.confirm(confirmOptions, (ref) => {
      this.acceptingOffer = true;

      this._offerService.acceptSupplierOffer(this.offerId).result(
        null,
        (res) => {
          this.acceptingOffer = false;

          this._message.ok({
            message: "cards.offer.base_info.accept_successful"
          });

          ref.close();
        },
        (error) => {
          this._message.error({
            message: "cards.offer.base_info.accepting_failed"
          });
          this.acceptingOffer = false;
        }
      );
    });
  }

  // public reject() {
  //   this._dialog.confirm({
  //     title: 'cards.offers.offer_dialogs.reject_offer.title',
  //     message: 'cards.offers.offer_dialogs.reject_offer.text'
  //   }, () => {
  //     this._offerService.rejectOffer(this.offerId)
  //       .result(this.form, () => {
  //         this._message.ok({
  //           message: 'cards.offers.offer_dialogs.reject_offer.offer_rejected'
  //         });
  //       });
  //   });
  // }

  public ngOnDestroy() {
    this._pipedriveService.removeLeadbooster();

    if (
      this._signalRService.isHubConnected(this._hubConnection) &&
      this.offerId
    ) {
      this._hubConnection.send("leaveOffer", this.offerId);
      this._hubConnection.off("statusChanged");
      this._hubConnection.off("offerHasChanged");
    }

    if (this._signalRSubscription) {
      this._signalRSubscription.unsubscribe();
    }

    if (this._offerTitleChangeSubscription) {
      this._offerTitleChangeSubscription.unsubscribe();
    }

    if (this._msgRef) {
      this._msgRef.dismiss();
    }
  }

  // OFFER FILES

  public openAddFileDialog(attachment: IAttachment = null) {
    const fileDialogData: IAddFileDialogData = {
      dataTableRef: this.filesList.dataSource,
      attachment: attachment,
      add: (data) => {
        return this.offerId
          ? this._offerService.addAttachment(
              this.offerId,
              this._getUpsertAttachmentDto(data)
            )
          : of(Object.assign({}, data, { id: Guid.newGuid() }));
      },
      save: (data) => {
        return this.offerId
          ? this._offerService.saveAttachment(
              this.offerId,
              attachment.id,
              this._getUpsertAttachmentDto(data)
            )
          : of(data);
      }
    };

    this._dialog.open(AddFileDialog, {
      data: fileDialogData
    });
  }

  private _getUpsertAttachmentDto(attachment: IAttachment) {
    return {
      id: attachment.id,
      name: attachment.name,
      description: attachment.description,
      fileId: attachment.fileId
    };
  }

  public downloadAttachment(attachment: IAttachment) {
    this._offerService.downloadAttachment(this.offerId, attachment.id);
  }

  public removeAttachment(attachment: IAttachment) {
    this._dialog.confirm(
      {
        title:
          "cards.products.product_additional_info_and_instructions.attachments.remove_product_attachment_title",
        message:
          "cards.products.product_additional_info_and_instructions.attachments.remove_product_attachment_message"
      },
      () => {
        const o = this.offerId
          ? this._offerService.removeAttachment(this.offerId, attachment.id)
          : of({});

        o.result(
          null,
          (res) => {
            const i = this.filesList.dataSource.data.indexOf(attachment);
            this.filesList.dataSource.data.splice(i, 1);
            this.filesList.dataSource._updateChangeSubscription();
            this._message.ok({
              message:
                "cards.products.product_additional_info_and_instructions.attachments.remove_success"
            });
          },
          (e) => {
            console.error(e);
            this._message.error({
              message:
                "cards.products.product_additional_info_and_instructions.attachments.remove_failed"
            });
          }
        );
      }
    );
  }

  // END OFFER FILES
}

@Component({
  selector: "supplier-offer-import-product-dialog",
  host: { class: "dialog" },
  templateUrl: "../../dialogs/import-product.dialog.html",
  styleUrls: ["../../dialogs/import-product.dialog.scss"]
})
export class SupplierOfferImportProductDialog {
  @ViewChild("download") private _download: ElementRef;

  public files: any = [];
  public fileId: string;

  public isFileLoading = false;
  public isProcessing = false;
  public invalidProducts = false;

  public importInfo: any;

  private offerId: string;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<SupplierOfferImportProductDialog>,
    private _fileService: FileService,
    private _importsService: ImportsService,
    private _offerService: OfferService,
    private _exportService: ExportService,
    private _message: LxmMessage
  ) {
    this.offerId = data.offerId;
  }

  public importInstructions = {
    prelude: "cards.offer.import_product_dialog.info.prelude",
    text: [
      "cards.offer.import_product_dialog.info.text1",
      "cards.offer.import_product_dialog.info.text2",
      "cards.offer.import_product_dialog.info.text3"
    ]
  };

  public get isimportInfoValid() {
    if (this.importInfo?.summary == null) {
      return true;
    }

    return this.importInfo.summary.isValid;
  }

  public get validationErrorsCount() {
    let total = 0;
    let results = this.importInfo.summary.results;

    for (let key in results) {
      if (results.hasOwnProperty(key)) {
        let prop = results[key];
        total += prop.errors.length;
      }
    }

    return total;
  }

  public uploadFile(fileList: FileList) {
    const file = fileList[0];

    const dto = {
      id: "",
      name: file.name,
      progress: 0,
      customText: null
    };
    this.files.push(dto);

    const formData = new FormData();
    formData.append("file", file);

    this._fileService.upload(formData).subscribe(async (event) => {
      switch (event.type) {
        case HttpEventType.UploadProgress:
          dto.progress = Math.round((100 * event.loaded) / event.total);
          this.isFileLoading = true;
          break;

        case HttpEventType.Response:
          await this.getImportData(event.body)
            .then((res) => {
              dto.id = event.body;
              this.fileId = event.body;
            })
            .catch(() => {
              this.deleteAttachment(0);
            });

          this.isFileLoading = false;
          break;
      }
    });
  }

  public deleteAttachment(index: number) {
    event.stopImmediatePropagation();

    if (!this.fileId || !this.files[index]) {
      return;
    }

    this.isFileLoading = true;
    this._fileService
      .delete(this.files[index].id)
      .toPromise()
      .then((res) => {
        this.files?.splice(index, 1);
        this.fileId = null;
        this.isFileLoading = false;
        this.importInfo = null;
      })
      .catch((e) => {
        this.isFileLoading = false;
      });
  }

  public onNoClick(): void {
    this.dialogRef.close(null);
  }

  public closeDialog() {
    this.dialogRef.close(null);
  }

  public downloadFruitsVegetablesOfferImportXls() {
    this._offerService
      .exportFruitsVegetablesOfferImportXls(this.offerId)
      .pipe(
        mergeMap(async (res) => {
          const blob = await this._offerService.downloadXls(res.id).toPromise();
          return {
            fileName: res.fileName,
            blob: blob
          };
        })
      )
      .pipe(
        map((res) => {
          const url = window.URL.createObjectURL(res.blob);

          const link = this._download.nativeElement;
          link.href = url;
          link.download = res.fileName;
          link.click();

          window.URL.revokeObjectURL(url);
        })
      )
      .toPromise();
  }

  public import() {
    this.isProcessing = true;

    var req = {
      offerId: this.offerId,
      fileId: this.fileId,
      uniqueCode: UniqueCodeTypeOption.EanOrSupplierCode
    };

    this._importsService.importFruitsVegetablesOfferProducts(req).result(
      null,
      (res: any) => {
        this.invalidProducts = false;
        this.isProcessing = false;
        this.deleteAttachment(0);

        this._message.ok({
          message: "cards.import.messages.import_success"
        });

        this.dialogRef.close({ success: true });
      },
      (err) => {
        this.invalidProducts =
          err.validationSummary == "import.error.failed_to_create_import_data";
        //this.validationResults = err.validationResults;

        this.isProcessing = false;

        if (err.validationSummary) {
          this._message.error({
            message: "cards." + err.validationSummary
          });
        }
      }
    );
  }

  public viewErrors() {
    const validationData = this.importInfo.summary.results;

    function getCsvData() {
      let arr = [];
      let columns = ["Row"];
      Object.keys(validationData).forEach((x) => {
        const row = parseInt(x.match(/\d+/)[0], 10);
        const field = x.split(".")[1];
        if (!columns.includes(field)) {
          columns.push(field);
        }
        const value = validationData[x];
        arr[row] = arr[row] || {};
        if (field && value)
          Object.assign(arr[row], {
            Row: row,
            [field]: value.errors.map((q) => q.error)
          });
      });

      return {
        data: arr.filter((row) => row !== null),
        columns
      };
    }

    const { data, columns } = getCsvData();
    this._exportService.exportToCsv(data, "errors", columns);
  }

  private async getImportData(fileId = this.fileId) {
    if (fileId) {
      this.files[0].customText = "cards.import.import_main.import_info_loading";
      this.importInfo = null;
      this._importsService
        .getLxmFruitsVegetablesOfferProductsImportData(
          fileId,
          UniqueCodeTypeOption.EanOrSupplierCode
        )
        .toPromise()
        .then((res: any) => {
          this.importInfo = res.offerProductsImport;
          this.files[0].customText =
            "cards.import.import_main.import_info_loading_complete";
        })
        .catch((e) => {
          this.files[0].progress = -1;
          this.files[0].error =
            "cards.import.import_main.import_info_loading_error";
        });
    }
  }
}
