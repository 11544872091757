import { Injectable } from '@angular/core';
import { BehaviorSubject, Subscription } from 'rxjs';
import { AuthenticationService } from '../_services/authentication.service';
import { RouteListener, currentRoute } from '../_helpers/route.listener';
import { IUser, IUserSettings, IUserInbox } from '../models';
import { LocaleService, ILanguage } from '../_services/locale.service';
import { SignalRService } from 'src/app/_services/signalR.service';
import { RETAILER_ID } from 'src/app/config';
import { LxmAppModule, UserAction } from '../enum';
import moment from 'moment';
import { UserService } from '../_services';
import { finalize } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class AppState {

  public userSettings: IUserSettings;
  public inbox: IUserInbox;
  private readonly _user: BehaviorSubject<IUser>;
  private readonly _currentTenantId: BehaviorSubject<string>;

  public timeZoneOffset;

  public supportedCampaignOfferExports = [
    RETAILER_ID.COOP_EST,
    RETAILER_ID.SELVER,
    RETAILER_ID.STOCKMANN,
    RETAILER_ID.FOODBIT,
    RETAILER_ID.RKIOSK,
    RETAILER_ID.RIMI_EST,
    RETAILER_ID.ALDAR,
    RETAILER_ID.BIOMARKET,
    RETAILER_ID.KAUBAMAJA,
    RETAILER_ID.GROSS,
    RETAILER_ID.PRISMA,
    RETAILER_ID.RRLEKTUS,
    RETAILER_ID.CoopEstHiiumaaTÜ,
    RETAILER_ID.CoopEstSaaremaaTÜ,
    RETAILER_ID.CoopEstHaapsaluTÜ,
    RETAILER_ID.CoopEstLihulaTÜ,
    RETAILER_ID.CoopEstHarjuTÜ,
    RETAILER_ID.CoopEstJärvaTÜ,
    RETAILER_ID.CoopEstRaplaTÜ,
    RETAILER_ID.CoopEstVändraTÜ,
    RETAILER_ID.CoopEstPõltsamaaMÜ,
    RETAILER_ID.CoopEstJõgevaMÜ,
    RETAILER_ID.CoopEstViljandiTÜ,
    RETAILER_ID.CoopEstTartuTK,
    RETAILER_ID.CoopEstKilingiNõmmeMÜ,
    RETAILER_ID.CoopEstAbjaTÜ,
    RETAILER_ID.CoopEstElvaTÜ,
    RETAILER_ID.CoopEstPõlvaTÜ,
    RETAILER_ID.CoopEstTõrvaTÜ,
    RETAILER_ID.CoopEstAntslaTÜ,
    RETAILER_ID.CoopEstVõruTÜ,
    RETAILER_ID.KTY,
    RETAILER_ID.MAXIMA,
    RETAILER_ID.OLEREX,
    RETAILER_ID.VESKI_MARKET,
    RETAILER_ID.TALU_TOIDAB,
    RETAILER_ID.DECORA,
    RETAILER_ID.CIRCLE_K,
    RETAILER_ID.ALEXELA,
    RETAILER_ID.KAUPMEES,
    RETAILER_ID.CLEAN_KITCHEN,
    RETAILER_ID.JAAGUMAE_KAUBANDUS,
    RETAILER_ID.PROMO_CC,
    RETAILER_ID.RÕNGU_PAGAR,
    RETAILER_ID.A1000,
    RETAILER_ID.BOLT_MARKET,
    RETAILER_ID.HORECA,
    RETAILER_ID.PINUS,
    RETAILER_ID.BALTIC_RESTAURANTS_ESTONIA,
    RETAILER_ID.FRUIT_XPRESS,
    RETAILER_ID.CAMPY,
    RETAILER_ID.WOLT_MARKET,
    RETAILER_ID.KASTANI_POOR_AARDEMAA,
    RETAILER_ID.SIP_SHOP,
    RETAILER_ID.HEPA,
    RETAILER_ID.RADISSON,
    RETAILER_ID.SYDAMEAPTEEK,
    RETAILER_ID.FOOD_EXPERT,
    RETAILER_ID.SUMENA,
    RETAILER_ID.TYDRUKUD,
  ];

  public supportedAssessmentFormExports = {
    optional: [
      RETAILER_ID.RIMI_EST,
      RETAILER_ID.RIMI_LV,
      RETAILER_ID.RIMI_LT
    ],
    required: [],
    requiredForAssortmentForms: [
      RETAILER_ID.COOP_EST,
      RETAILER_ID.CoopEstHiiumaaTÜ,
      RETAILER_ID.CoopEstSaaremaaTÜ,
      RETAILER_ID.CoopEstHaapsaluTÜ,
      RETAILER_ID.CoopEstLihulaTÜ,
      RETAILER_ID.CoopEstHarjuTÜ,
      RETAILER_ID.CoopEstJärvaTÜ,
      RETAILER_ID.CoopEstRaplaTÜ,
      RETAILER_ID.CoopEstVändraTÜ,
      RETAILER_ID.CoopEstPõltsamaaMÜ,
      RETAILER_ID.CoopEstJõgevaMÜ,
      RETAILER_ID.CoopEstViljandiTÜ,
      RETAILER_ID.CoopEstTartuTK,
      RETAILER_ID.CoopEstKilingiNõmmeMÜ,
      RETAILER_ID.CoopEstAbjaTÜ,
      RETAILER_ID.CoopEstElvaTÜ,
      RETAILER_ID.CoopEstPõlvaTÜ,
      RETAILER_ID.CoopEstTõrvaTÜ,
      RETAILER_ID.CoopEstAntslaTÜ,
      RETAILER_ID.CoopEstVõruTÜ,
    ]
  };

  public supportedDeliveryFormExports = {
    requiredForAssortmentForms: [
      RETAILER_ID.BOLT_MARKET,
    ],
    requiredForLogisticsForms: [
      RETAILER_ID.WOLT_MARKET,
    ]
  };

  public supportedEcommDecisionExports = [
    RETAILER_ID.SELVER,
    RETAILER_ID.KAUBAMAJA,
  ];

  public supportedProductSync = [
    RETAILER_ID.SELVER,
    RETAILER_ID.KAUBAMAJA,
  ];

  constructor(
    private _localeService: LocaleService,
    private _authService: AuthenticationService,
    private _routeListener: RouteListener,
    private _signalR: SignalRService,
    private _userService: UserService
  ) {
    this._user = new BehaviorSubject<IUser>({} as IUser);
    this._currentTenantId = new BehaviorSubject<string>(null);

    this._authService.jwt.subscribe(val => {
      if (!val) {
        this._user.next({} as IUser);
        this._currentTenantId.next(null);
        this.userSettings = null;
      } else {
        this._user.next({
          id: val.nameid,
          name: val.given_name || val.unique_name ? `${val.given_name} ${val.unique_name}`.trim() : null,
          email: val.email
        });

        this._currentTenantId.next(val.CurrentTenantId);

        this.timeZoneOffset = moment.tz.zone(val.TenantTimeZoneId)?.utcOffset(0);

        this._initSignalR();

      }

    });
  }

  public get user() { return this._user.value; };
  public get language() { return this._localeService.currentLanguage; };
  public get languageOptions() { return this._localeService.languageOptions; };
  public get locale() { return this._localeService.locale; };
  public get currentLanguage() { return this._localeService.currentLanguage; };
  public get isLoggedIn(): any { return this._authService.isLoggedIn(); };
  public get currentRoute() { return currentRoute; };
  public get contentLanguages() { return this.userSettings?.contentLanguages; };
  public get displayContentLanguage() { return this.userSettings?.displayContentLanguage; };
  public get defaultCurrency() { return this.userSettings?.defaultCurrency; };
  public get contentTenantId() { return this._currentTenantId.value; };

  private _initSignalR() {

    this._signalR.commonHub
      .subscribe(x => {
        if (!x) {
          return;
        }
        x.on('updateContentLanguages', res => {
          this.userSettings.contentLanguages = res.contentLanguages;
          this.userSettings.displayContentLanguage = res.displayContentLanguage;
        });
        x.on('updateCompanyName', name => {
          const menuitem = this.userSettings.tenants.find(t => t.id === this._authService.currentTenantId.value);
          if (menuitem) {
            menuitem.name = name;
          }
        });
        x.on('newOffers', (data: IUserInbox) => {
          this.inbox.offersBadgeCount = data.offersBadgeCount;
        });
        x.on('newOrders', (data: IUserInbox) => {
          this.inbox.ordersBadgeCount = data.ordersBadgeCount;
          this.inbox.purchaseOrdersBadgeCount = data.purchaseOrdersBadgeCount;
          this.inbox.salesOrdersBadgeCount = data.salesOrdersBadgeCount;
        });
        x.on('newNotifications', (data: IUserInbox) => {
          this.inbox.notificationsBadgeCount = data.notificationsBadgeCount;
        });

        x.on('updateInbox', (data: any) => {

          let sub: Subscription;
          switch (data.type) {

            case "purchaseOrders":
              sub = this._userService.getUnreadPurchaseOrdersCount()
                .pipe(finalize(() => {
                  sub.unsubscribe();
                }))
                .subscribe((x: number) => {
                  this.inbox.purchaseOrdersBadgeCount = x;
                  this.inbox.ordersBadgeCount = this.inbox.purchaseOrdersBadgeCount + this.inbox.salesOrdersBadgeCount;
                });
              break;

            case "salesOrders":
              sub = this._userService.getUnreadSalesOrdersCount()
                .pipe(finalize(() => {
                  sub.unsubscribe();
                }))
                .subscribe((x: number) => {
                  this.inbox.salesOrdersBadgeCount = x;
                  this.inbox.ordersBadgeCount = this.inbox.purchaseOrdersBadgeCount + this.inbox.salesOrdersBadgeCount;
                });
              break;
          }

        });
      });
  }

  // Activates before everything else
  public initializeApp(language: ILanguage) {
    this._localeService.setLanguage(language);
    this._routeListener.initializeListener();
  }

  public hasModule(module: LxmAppModule): boolean {
    return this._authService.hasModule(module);
  }

  public hasRight(actions: UserAction[]): boolean {
    return this._authService.hasRight(actions);
  }

  public hasRights(actions: UserAction[]): boolean {
    return this._authService.hasRights(actions);
  }

  public isVisitor() {
    return this._authService.jwt.value?.Visitor;
  }
}
