<card class="mb20" [formGroup]="form" *ngIf="!edit">
  <div header-title>
    <owner-select [readonly]="!canEdit" [options]="ownerOptions" (change)="onOwnerChange($event)" [formGroup]="form"
      for="offerOwner">
    </owner-select>
  </div>

  <div header-actions>
    <status-indicator class="mt10" [status]="getOfferStatusProgress(offer?.status)"
      [description]="offer?.status | translateEnum : 'SupplierOfferStatus'">
    </status-indicator>

    <ng-container *ngIf="canAccept">
      <lxm-button class="ml15" type="green" icon="accept" (click)="accept()"
        *ngIf="!isSupplierOfferConfirmDisabled && !hasAutomaticConfirmation"
        label="cards.offer.base_info.action.approve_conditions" [loading]="acceptingOffer">
      </lxm-button>
    </ng-container>
    <ng-container *ngIf="canEdit">
      <lxm-button class="ml15" type="green" icon="send" (click)="send()" [loading]="sendingOffer"
        [label]="isOfferModifiedAfterSent ? 'cards.offer.base_info.action.send_changes_to_retailer' : 'cards.offer.base_info.action.send_to_retailer'"
        *requiredRights="[UserAction.ManageSupplierOffers]"
        [disabled]="isProSelected || (offerTypeId == OfferType.Campaign && appState.supportedCampaignOfferExports.indexOf(form.value.retailer?.id) < 0)">
      </lxm-button>
    </ng-container>

    <dot-menu class="ml15" [template]="optionsTemplate">
      <ng-template #optionsTemplate>
        <ul>
          <li (click)="exportXls()" *ngIf="isExportXlsEnabled"
            [class.disabled]="isProSelected || (offerTypeId == OfferType.Campaign && appState.supportedCampaignOfferExports.indexOf(form.value.retailer?.id) < 0)">
            <lxm-button icon="excel" label="action.download_xls"></lxm-button>
          </li>
          <li (click)="exportPdf()" *ngIf="isExportPdfEnabled"
            [class.disabled]="isProSelected || (offerTypeId == OfferType.Campaign && appState.supportedCampaignOfferExports.indexOf(form.value.retailer?.id) < 0)">
            <lxm-button icon="pdf" label="action.download_pdf"></lxm-button>
          </li>
          <li (click)="downloadImages()">
            <lxm-button icon="download-images" label="action.download_images"></lxm-button>
          </li>
          <li (click)="clone()" *requiredRights="[UserAction.ManageSupplierOffers]">
            <lxm-button icon="copy" label="action.clone"></lxm-button>
          </li>
          <ng-container *ngIf="canDelete">
            <li (click)="deleteOffer()" *requiredRights="[UserAction.ManageSupplierOffers]">
              <lxm-button icon="trash" label="action.delete"></lxm-button>
            </li>
          </ng-container>
        </ul>
      </ng-template>
    </dot-menu>
  </div>
</card>

<card [formGroup]="form" [translationsPath]="translationsPath" [title]="offerTitle">
  <div header-actions>
    <ng-container [ngSwitch]="edit">
      <ng-container *ngSwitchCase="false">
        <ng-container *ngIf="canEdit">
          <lxm-button class="ml15" type="beige" icon="edit" title="action.edit"
            *requiredRights="[UserAction.ManageSupplierOffers]" (click)="toggleEdit()"></lxm-button>
        </ng-container>
      </ng-container>
      <ng-container *ngSwitchCase="true">
        <lxm-button class="ml15" type="grey" icon="back" label="action.cancel"
          (click)="offerId ? toggleEdit() : backToList()"></lxm-button>
        <lxm-button class="ml15" type="beige" icon="save" label="action.save" [loading]="saveLoading" (click)="save()"
          buttonType="submit"></lxm-button>
      </ng-container>
    </ng-container>
  </div>

  <div body>
    <card-section [ngSwitch]="edit">
      <!-- NEW UI -->

      <ng-container *ngSwitchDefault>
        <div class="row gap-2">
          <!-- RETAILER -->
          <div class="col-3 !flex-1 no-padding">
            <form-field label="retailer" labelPosition="top" description="retailer" [readonly]="!edit">
              <div text class="flex flex-col input-padding readonly">
                <div class="flex align-center">
                  <span>
                    {{form.value.retailer?.name || '-'}}
                  </span>
                  <span class="pro-icon ml5" *ngIf="isProSelected"></span>
                </div>
                <a *ngIf="form.value?.retailer?.id as retailerId" class="mt5 greyed link wmc"
                  [href]="'/clients/' + retailerId" target="_blank">
                  {{'action.view_profile' | translate}}
                </a>
              </div>
            </form-field>
          </div>

          <!-- RECIPIENT -->
          <div class="col-3 !flex-1 no-padding">
            <form-field label="purchase_manager" labelPosition="top" description="purchase_manager" [readonly]="!edit">
              <div text class="flex flex-col input-padding readonly">
                <span *ngIf="!selectedRecipients?.length">-</span>
                <div class="flex flex-col" *ngFor="let recipient of selectedRecipients let i = index"
                  [class.mb4]="i !== selectedRecipients?.length - 1">
                  <span *ngIf="recipient.name">{{recipient.name || '-'}}</span>
                  <a *ngIf="recipient.email" [class.mt5]="recipient.name" class="greyed wmc"
                    href="mailto:{{recipient.email}}">
                    {{recipient.email}}
                  </a>
                  <span *ngIf="!recipient.email" class="mt5 greyed wmc">
                    {{'global.no_email' | translate}}
                  </span>
                </div>
              </div>
            </form-field>
          </div>

          <!-- OFFER PERIOD -->
          <div class="col-3 !flex-1 no-padding" *ngIf="showOfferPeriodField">
            <form-field [label]="offerTypeId == OfferType.Campaign ? 'campaign_offer_date' : 'offer_date'"
              labelPosition="top"
              [description]="offerTypeId == OfferType.Campaign ? 'campaign_offer_date' : 'offer_date'"
              [readonly]="!edit">
              <div text class="flex flex-col input-padding readonly">
                <div>
                  <span>{{offerDateFrom | formDate}}</span>
                  <span>&nbsp;-&nbsp;</span>
                  <span>{{offerDateTo | formDate}}</span>
                </div>
                <div class="mt5 greyed" *ngIf="offerTypeId == OfferType.Campaign && retailerCampaigns.length">
                  {{form.get('retailerCampaign').value?.value}}
                </div>
              </div>
            </form-field>

            <form-field label="procurement_supply_date" labelPosition="top" *ngIf="showOfferSupplyPeriodField"
              description="procurement_supply_date" [readonly]="!edit">
              <div text class="row align-center input-padding readonly">
                <span class>{{procurement.supplyFrom | formDate}}</span>
                <span>&nbsp;-&nbsp;</span>
                <span class>{{procurement.supplyTo | formDate}}</span>
              </div>
            </form-field>
          </div>

          <!-- OFFER DATE FROM -->
          <div class="col-3 !flex-1 no-padding" *ngIf="showOfferDateFromField">
            <form-field label="price_changes_offer_valid_from_date" labelPosition="top"
              description="price_changes_offer_valid_from_date" [readonly]="!edit">
              <div text class="row align-center input-padding readonly">
                <span>{{offerDateFrom | formDate}}</span>
              </div>
            </form-field>
          </div>
        </div>

        <div class="row gap-2">
          <!-- OFFER TYPE -->
          <div class="col-3 !flex-1 no-padding">
            <form-field label="offer_type" labelPosition="top" description="offer_type" [readonly]="!edit">
              <div text class="input-padding readonly">
                <lxm-radio for="offerType" [options]="offerTypes" [readonly]="true" class="offer-type"
                  discriminator="code" labelField="translatedValue" [isTranslated]="true">
                </lxm-radio>
              </div>
            </form-field>
          </div>

          <!-- Assistants -->
          <div class="col-3 !flex-1 no-padding">
            <form-field *ngIf="selectedAssistants?.length > 0" label="purchase_manager_assistants" labelPosition="top"
              [readonly]="!edit">
              <div text class="flex flex-col input-padding readonly">
                <span *ngIf="!selectedAssistants?.length">-</span>
                <div class="flex flex-col" *ngFor="let assistant of selectedAssistants let i = index"
                  [class.mb4]="i !== selectedAssistants?.length - 1">
                  <span>{{assistant.fullName || '-'}}</span>
                  <a *ngIf="assistant.email" class="mt5 greyed" href="mailto:{{assistant.email}}"
                    style="width: max-content">
                    {{assistant.email}}
                  </a>
                  <span *ngIf="!assistant.email" class="mt5 greyed" style="width: max-content">
                    {{'global.no_email' | translate}}
                  </span>
                </div>
              </div>
            </form-field>
          </div>

          <div class="col-3 !flex-1 no-padding"></div>
        </div>

        <div class="row" *ngIf="offerCurrency">
          <div class="col-12 no-padding">
            <form-field label="currency" labelPosition="top">
              <span text class="input-padding readonly">
                {{offerCurrency.value}} ({{offerCurrency.code}})
              </span>
            </form-field>
          </div>
        </div>

        <div class="row" *ngIf="isProcurement">
          <div class="col-12 no-padding">
            <form-field label="procurement_description" labelPosition="top" description="procurement_description"
              [readonly]="!edit">
              <quill-view any class="w-full" [content]="procurementDescription"></quill-view>
            </form-field>
          </div>
        </div>

        <div class="row">
          <div class="col-12 no-padding">
            <form-field label="offer_description" labelPosition="top" description="offer_description"
              [readonly]="!edit">
              <div text class="pre-line input-padding readonly">
                {{offerDescription || '-'}}
              </div>
            </form-field>
          </div>
        </div>

        <div class="row" *ngIf="canIncludeProductsPdf">
          <div class="col-12 no-padding">
            <form-field label="include_products_pdf" labelPosition="top" description="include_products_pdf"
              [readonly]="!edit">
              <div text class="pre-line input-padding readonly">
                {{form.get('includeProductsPdf').value | translateEnum:'Bool'}}
              </div>
            </form-field>
          </div>
        </div>
      </ng-container>

      <!-- END NEW UI -->

      <ng-container *ngSwitchCase="true">
        <ng-container [ngSwitch]="offerTypeId">
          <!-- DEFAULT EDIT VIEW -->
          <ng-container *ngSwitchDefault>
            <div class="row">
              <div class="col-6 no-padding">
                <form-field [required]="edit" label="retailer" description="retailer" [readonly]="!edit">
                  <div any class="flex flex-col f-1">
                    <lxm-select for="retailer" class="w-full" labelField="name" [searchable]="true"
                      [options]="retailers" [sortByLabel]="true" [sortByFn]="sortClient" [clearable]="false"
                      [readonly]="!edit || !canEditRetailer">
                    </lxm-select>
                    <ng-container *hasRight="[UserAction.ViewClient]">
                      <a *ngIf="form.value?.retailer?.id as retailerId" class="flex f-0 mt10 ml10 greyed link wmc"
                        [href]="'/clients/' + retailerId" target="_blank">
                        {{'action.view_profile' | translate}}
                      </a>
                    </ng-container>

                  </div>

                  <validation-errors for="retailerId" aliasTo="retailer"></validation-errors>
                </form-field>

                <form-field label="currency" [required]="edit">
                  <lxm-select [readonly]="edit || !canEditCurrency" select for="currency" class="w-full"
                    labelField="value" compareField="id" [searchable]="true" [options]="currencyOptions"
                    [sortByLabel]="true" [clearable]="false"></lxm-select>
                  <validation-errors for="currencyId" aliasTo="currency"></validation-errors>
                </form-field>

                <form-field [required]="edit" label="purchase_manager" description="purchase_manager"
                  [readonly]="!edit">
                  <lxm-select select for="recipients" class="w-full" labelField="label"
                    [addTag]="isCustomRecipientDisabled ? false : addNewRecipient"
                    [addTagText]="translationsPath + '.label.add_new_recipient' | translate" [searchable]="true"
                    [multiple]="true" [options]="recipientsOptions" [readonly]="!edit || isRequestedOffer"
                    compareField="userId">
                    <lxm-button *ngIf="showAddNewContactButton" header label="action.add_new" icon="plus-dark"
                      (click)="openAddNewContactDialog()">
                    </lxm-button>
                  </lxm-select>
                  <validation-errors for="recipients"></validation-errors>
                </form-field>

                <form-field *ngIf="selectedAssistants?.length > 0" label="purchase_manager_assistants">
                  <lxm-select select for="assistants" class="w-full" labelField="label" [multiple]="true"
                    [readonly]="true" compareField="id">
                  </lxm-select>
                </form-field>
              </div>
              <div class="col-6 no-padding" *ngIf="offerId">
                <form-field label="offer_status" description="offer_status" [readonly]="!edit">
                  <div any>
                    <status-indicator [status]="getOfferStatusProgress(offer?.status)"
                      [description]="offer?.status | translateEnum : 'SupplierOfferStatus'"></status-indicator>
                  </div>
                </form-field>
              </div>
            </div>

            <div class="row">
              <div class="col-12 no-padding">
                <form-field [required]="edit" label="offer_type" description="offer_type" [readonly]="!edit">
                  <lxm-radio any for="offerType" [options]="offerTypes" [readonly]="!edit || isRequestedOffer"
                    class="offer-type" discriminator="code" labelField="translatedValue" [isTranslated]="true">
                  </lxm-radio>
                  <validation-errors for="offerType"></validation-errors>
                </form-field>

                <form-field label="retailer_campaign"
                  *ngIf="offerTypeId == OfferType.Campaign && retailerCampaigns.length" [required]="!edit"
                  description="retailer_campaign" [readonly]="!edit">
                  <lxm-select select for="retailerCampaign" [options]="retailerCampaigns" labelField="value"
                    [readonly]="!edit">
                  </lxm-select>
                  <validation-errors for="retailerCampaign"></validation-errors>
                </form-field>

                <form-field [required]="edit" [readonly]="!edit" label="offer_name" for="name" type="input-text"
                  inputClass="w60p" description="offer_name" [readonly]="!edit">
                  <validation-errors for="name"></validation-errors>
                </form-field>

                <ng-container *ngIf="offerTypeId != OfferType.Logistics && offerTypeId != OfferType.PriceChanges">
                  <form-field [required]="edit"
                    [label]="offerTypeId == OfferType.Campaign ? 'campaign_offer_date' : 'offer_date'"
                    [readonly]="!edit"
                    [description]="offerTypeId == OfferType.Campaign ? 'campaign_offer_date' : 'offer_date'"
                    [readonly]="!edit">
                    <div any class="row align-center" style="margin-top: -10px;">
                      <div class="date-range mr10 mt10" [ngClass]="{'date-range--edit': edit}">
                        <lxm-datepicker for="dateFrom"></lxm-datepicker>
                        <div class="date-range__separator">-</div>
                        <lxm-datepicker for="dateTo"></lxm-datepicker>
                      </div>
                      <div class="flex flex-row flex-wrap">
                        <button tabindex="-1" class="greyed-button date-period-button mw96 mr10 mt10"
                          (click)="setDatePeriod('week')" [class.active]="period === 'week'">
                          {{'global.period.week' | translate}}
                        </button>
                        <button tabindex="-1" class="greyed-button date-period-button mw96 mr10 mt10"
                          (click)="setDatePeriod('month')" [class.active]="period === 'month'">
                          {{'global.period.month' | translate}}
                        </button>
                        <button tabindex="-1" class="greyed-button date-period-button mw96 mr10 mt10"
                          (click)="setDatePeriod('year')" [class.active]="period === 'year'">
                          {{'global.period.year' | translate}}
                        </button>
                        <button tabindex="-1" class="greyed-button date-period-button mw96 mr10 mt10"
                          (click)="setDatePeriod('termless')" [class.active]="period === 'termless'">
                          {{'global.period.termless' | translate}}
                        </button>
                      </div>
                    </div>
                    <validation-errors for="validFrom"></validation-errors>
                    <validation-errors for="validTo"></validation-errors>
                  </form-field>
                </ng-container>

                <ng-container *ngIf="offerTypeId == OfferType.PriceChanges">
                  <form-field [required]="edit" label="price_changes_offer_valid_from_date" [readonly]="!edit"
                    description="price_changes_offer_valid_from_date" [readonly]="!edit">
                    <div any class="row align-center" style="margin-top: -10px;">
                      <div class="date-range mr10 mt10">
                        <lxm-datepicker for="dateFrom"></lxm-datepicker>
                      </div>
                    </div>
                    <validation-errors for="validFrom"></validation-errors>
                  </form-field>
                </ng-container>

                <form-field label="offer_description" for="description" type="textarea" [required]="edit"
                  description="description" [readonly]="!edit">
                  <validation-errors for="description"></validation-errors>
                </form-field>

                <form-field label="include_products_pdf" *ngIf="canIncludeProductsPdf" [required]="edit"
                  description="include_products_pdf" [readonly]="!edit">
                  <slide-toggle toggle for="includeProductsPdf" [readonly]="!edit"></slide-toggle>
                  <validation-errors for="includeProductsPdf"></validation-errors>
                </form-field>
              </div>
            </div>
          </ng-container>

          <!-- PROCUREMENT EDIT VIEW -->
          <ng-container *ngSwitchCase="OfferType.Procurement">
            <div class="row">
              <div class="col-6 no-padding">
                <form-field [required]="edit" label="retailer">
                  <lxm-select select for="retailer" class="w-full" labelField="name" [searchable]="true"
                    [options]="retailers" [sortByLabel]="true" [sortByFn]="sortClient"
                    [readonly]="!edit || !canEditRetailer">
                  </lxm-select>
                  <validation-errors for="retailerId" aliasTo="retailer"></validation-errors>
                </form-field>

                <form-field label="currency" [required]="edit">
                  <lxm-select [readonly]="!edit || currencyOptions.length < 2" select for="currency" class="w-full"
                    labelField="value" compareField="id" [searchable]="true" [options]="currencyOptions"
                    [sortByLabel]="true" [clearable]="false"></lxm-select>
                  <validation-errors for="currencyId" aliasTo="currency"></validation-errors>
                </form-field>

                <form-field [required]="edit" label="purchase_manager">
                  <lxm-select select for="recipients" class="w-full" labelField="label" [addTag]="addNewRecipient"
                    [addTagText]="translationsPath + '.label.add_new_recipient' | translate" [searchable]="true"
                    [multiple]="true" [options]="recipientsOptions" [readonly]="!edit || isRequestedOffer"
                    compareField="userId">
                  </lxm-select>
                  <validation-errors for="recipients"></validation-errors>
                </form-field>
              </div>
              <div class="col-6 no-padding" *ngIf="offerId">
                <form-field label="offer_status">
                  <div any>
                    <status-indicator [status]="getOfferStatusProgress(offer?.status)"
                      [description]="offer?.status | translateEnum : 'SupplierOfferStatus'"></status-indicator>
                  </div>
                </form-field>
              </div>
            </div>

            <div class="row">
              <div class="col-12 no-padding">
                <form-field [required]="edit" label="offer_type">
                  <lxm-radio any for="offerType" [options]="offerTypes"
                    [readonly]="!edit || isRequestedOffer || fromCalendarEntry" class="offer-type" discriminator="code"
                    labelField="translatedValue" [isTranslated]="true">
                  </lxm-radio>
                  <validation-errors for="offerType"></validation-errors>
                </form-field>

                <form-field [required]="edit" [readonly]="!edit || fromCalendarEntry || !canChangeProcurement"
                  label="procurement_name">
                  <lxm-select select [options]="procurements" for="procurement" labelField="name" [isTranslated]="true"
                    [clearable]="false">
                  </lxm-select>
                  <validation-errors for="procurementId" aliasTo="procurement"></validation-errors>
                </form-field>

                <ng-container *ngIf="procurement">
                  <form-field label="procurement_period">
                    <div any class="row align-center input-padding readonly">
                      <span class>{{procurement.validFrom | formDate}}</span>
                      <span class="date-range__separator">-</span>
                      <span class>{{procurement.validTo | formDate}}</span>
                    </div>
                  </form-field>

                  <form-field label="procurement_supply_date">
                    <div text class="row align-center input-padding readonly">
                      <span class>{{procurement.supplyFrom | formDate}}</span>
                      <span class="date-range__separator">-</span>
                      <span class>{{procurement.supplyTo | formDate}}</span>
                    </div>
                  </form-field>

                  <form-field label="procurement_description">
                    <quill-view class="input-padding readonly" any
                      [content]="procurement.description | translatedValue"></quill-view>
                  </form-field>
                </ng-container>

                <form-field label="offer_description" for="description" type="textarea" [readonly]="!edit">
                  <validation-errors for="description"></validation-errors>
                </form-field>
              </div>
            </div>
          </ng-container>
        </ng-container>
      </ng-container>

      <div class="row mt12" *ngIf="isProSelected">
        <div class="col-12 no-padding">
          <card-message type="message">
            <div class="inline">
              <span>
                {{'cards.offer.pro_warning_text' | translate}}
              </span>
              <a class="bold link" routerLink="/lxm-settings/packageinfo">
                {{'cards.offer.pro_warning_change_package' | translate}}
              </a>
            </div>
          </card-message>
        </div>
      </div>
    </card-section>

    <div class="separator mt20 mb20"></div>

    <ng-container [ngSwitch]="offerTypeId">
      <card-section *ngSwitchDefault>
        <span header-title>{{'cards.offer.products.title' | translate}}</span>
        <div header-actions *requiredModules="['CreateSalesOrders']">
          <slide-toggle *ngIf="isOfferTypeOrderable" for="canOrder" yes="cards.offer.offer_is_orderable"
            no="cards.offer.offer_is_not_orderable" [readonly]="!edit">
          </slide-toggle>
        </div>
        <div body>
          <info-array [info]="productInstructions" class="row mb20" *ngIf="edit"></info-array>

          <supplier-offer-products-list for="products" [date]="form.get('dateFrom').value"
            [retailerId]="form.get('retailer').value?.id" [currency]="form.get('currency').value"
            [countryCode]="form.get('retailer').value?.countryCode" [offerType]="offerTypeId" [readonly]="!edit"
            [canAddProducts]="!isRequestedOffer" [softDelete]="isRequestedOffer" [retailerHasRPim]="retailerHasRPim"
            [isOfferSent]="offerLastSentDate ? true : false"
            [canOrder]="isOfferTypeOrderable && form.get('canOrder').value" (onSort)="sortOrderDefined = true"
            [campaignType]="campaignType">
          </supplier-offer-products-list>
        </div>
      </card-section>

      <card-section *ngSwitchCase="OfferType.Procurement">
        <span header-title>{{'cards.offer.procurement.products.title' | translate}}</span>

        <div header-actions class="flex flex-row align-center">
          <ng-container *ngIf="edit && offerId">
            <lxm-button (click)="openImportProductDialog()" class="ml15" type="green" icon="imports"
              label="cards.offer.base_info.action.fruits_vegetables_offer_products_import">
            </lxm-button>
          </ng-container>
          <ng-container *requiredModules="['CreateSalesOrders']">
            <slide-toggle class="ml15" *ngIf="isOfferTypeOrderable" for="canOrder" yes="cards.offer.offer_is_orderable"
              no="cards.offer.offer_is_not_orderable" [readonly]="!edit">
            </slide-toggle>
          </ng-container>
        </div>

        <div body [formGroup]="procurementProductsFormGroup">
          <div *ngIf="!procurement?.items?.length">
            <no-content image="no-products-inline" text="global.no_products_added" [inline]="true"></no-content>
          </div>

          <ng-container *ngFor="let item of procurement?.items; let i = index">
            <section-expanding class="procurement-items-section" [headerFullWidth]="true"
              [expanded]="procurementProductsFormGroup.get(item.id).value?.length">
              <div header-title class="procurement-header">
                <span class="procurement-header__name">
                  {{item.name}}
                </span>
                <span class="procurement-header__amount">
                  {{item.amount}}
                </span>
                <span class="procurement-header__comment" *ngIf="item.comment">
                  <ng-container *ngIf="item.amount !== null">, </ng-container>{{item.comment}}
                </span>
              </div>
              <div header-actions class="procurement-total">
                {{'global.total' | translate}}:
                {{procurementProductsFormGroup.get(item.id).value?.length || 0}}
              </div>

              <div body>
                <supplier-offer-products-list for="{{item.id}}" [date]="offerDateFrom"
                  [retailerId]="form.get('retailer').value?.id" [currency]="form.get('currency').value"
                  [countryCode]="form.get('retailer').value?.countryCode" [offerType]="offerTypeId" [readonly]="!edit"
                  [canAddProducts]="!isRequestedOffer" [softDelete]="isRequestedOffer" [showTotal]="false"
                  [retailerHasRPim]="retailerHasRPim" [canOrder]="isOfferTypeOrderable && form.get('canOrder').value"
                  [showNoContentText]="!edit">
                </supplier-offer-products-list>
              </div>
            </section-expanding>
          </ng-container>
        </div>
      </card-section>
    </ng-container>
  </div>

  <div footer class="flex justify-end" *ngIf="edit">
    <div class="flex align-center">
      <lxm-button class="ml15" type="grey" icon="back" label="action.cancel"
        (click)="offerId ? toggleEdit() : backToList()"></lxm-button>
      <lxm-button class="ml15" type="beige" icon="save" label="action.save" [loading]="saveLoading" (click)="save()"
        buttonType="submit"></lxm-button>
    </div>
  </div>
</card>

<a class="no-display" #download></a>